<div  class="layout-grid">
	<div class="menu-items">
		<ul app-submenu style="height: 75%"  [item]="model" root="true" class="ultima-menu ultima-main-menu clearfix"
			visible="true" parentActive="true">
		</ul>
	</div>
	<div class="footer">
		<div style="bottom: 11px;position:fixed; color: white; text-align: center">
			Version: {{app.appVersion}}
		</div>
	</div>
</div>


