import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {DynamicDialogConfig} from 'primeng/dynamicdialog';

@Component({
	selector: 'app-report-previewer',
	templateUrl: './report-previewer.component.html',
	styleUrls: ['./report-previewer.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class ReportPreviewerComponent implements OnInit {
	reportDetails: any;
	parameters: any;
	baseUrl = 'https://staging-reports.vmgonlineservices.co.za/api/reports/';
	reportConfig = {};
	title: string;
	viewerContainerStyle = {
		position: 'relative',
		width: '60vw',
		height: '80vh',
		['font-family']: 'ms sans serif'
	};

	constructor(private _config: DynamicDialogConfig) {
		this.reportDetails = this._config.data['reportDetails'];
		this.parameters = this._config.data['parameters'];
		this.title = this.parameters.ReportName;
	}

	public async ngOnInit(): Promise<void> {
		this.reportConfig = {
			report: `${this.reportDetails.ReportName}.trdp`,
			parameters: this.parameters,
		};
	}

}
