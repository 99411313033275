// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	type: 'development',
	/*gatewayApiBaseUrl: 'http://192.168.1.3:50000/api'*/
	// gatewayApiBaseUrl: 'https://staging-gateway.vmgonlineservices.co.za/api'
/*	gatewayApiBaseUrl: 'https://staging-gateway.vmgonlineservices.co.za/api'*/
	authBaseUrl: 'https://staging-auth.vmgonlineservices.co.za',
	dealershipBaseUrl: 'https://staging-dealership.vmgonlineservices.co.za',
	vehicleInfoBaseUrl: 'https://staging-vehicleinfo.vmgonlineservices.co.za',
	workshopBaseUrl: 'https://localhost:50002',
	crmBaseUrl: 'https://localhost:50002',
	reportBaseUrl: 'https://staging-reports.vmgonlineservices.co.za',
	feedsBaseUrl: 'https://localhost:50002'

};

// dealershipApiBaseUrl: 'http://192.168.1.125:55000',
// 	globalApiBaseUrl: 'http://192.168.1.125:52000',
// 	authApiBaseUrl: 'http://192.168.1.125:53000'

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
