import {Component, Inject, OnInit} from '@angular/core';
import {DynamicDialogConfig} from 'primeng/dynamicdialog';
import {NotificationsService} from '../../services/notifications/notifications.service';

@Component({
  selector: 'app-multiple-report-previewer',
  templateUrl: './multiple-report-previewer.component.html',
  styleUrls: ['./multiple-report-previewer.component.scss']
})
export class MultipleReportPreviewerComponent implements OnInit {

	constructor(private _config: DynamicDialogConfig,
				private _notificationService: NotificationsService) {
	}
	@Inject(DynamicDialogConfig)

	public reports: any;
	public selectedReportIndex = 0;
	public showScrollButtons: boolean = false;

	public reportDetails: any;
	public parameters: any;
	public baseUrl = 'https://staging-reports.vmgonlineservices.co.za/api/reports/';
	public title: string;
	public viewerContainerStyle = {
		position: 'relative',
		width: 'auto',
		height: '75vh',
		['font-family']: 'ms sans serif'
	};

	public onCloseCallback: () => void;

	public ngOnInit(): void {
		try {
			this.onCloseCallback = this._config.data.onCloseCallback;

			this.getAllReports();
		} catch (error) {
			this._notificationService.showErrorToastMessage('Failed to load reports', error);
		}
	}

	private getAllReports(): void {
		const reports = this._config.data.reportConfigs;

		if (reports && reports.length > 0) {
			this.reports = reports;

			const firstReport = reports[0];
			this.reportDetails = firstReport.reportDetails;
			this.reports.reportName = firstReport.reportName;

			this.loadReport(firstReport);
		}
	}

	private loadReport(reportConfig: any): void {
		this.selectedReportIndex = this.reports.findIndex(report => report === reportConfig);
		this.showScrollButtons = this.reports.length >= 8;
	}

	public onTabChange(event: any): void {
		const selectedReport = this.reports[event.index];
		this.loadReport(selectedReport);
	}
	public onTabClose(event: any): void {
		this.reports.splice(event.index, 1);

		if (this.reports.length === 0) {
			if (this.onCloseCallback) {
				this.onCloseCallback();
			}
		} else if (this.selectedReportIndex >= this.reports.length) {
			this.selectedReportIndex = this.reports.length - 1;
		}

		event.close();
	}
}
