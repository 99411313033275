<p-confirmDialog [style]="{width: '20vw', 'box-shadow': '0 10px 20px rgb(0 0 0 / 19%), 0 6px 6px rgb(0 0 0 / 23%)',
    'font-size': '1em',
    border: '1px solid #eaeaea'}"></p-confirmDialog>

<p-toast position="top-right" key="requiredFieldsToast">
	<ng-template let-message pTemplate="message">
		<span class="pi {{message?.data}}" style="font-size:2em; float:left;"></span>
		<div style="margin-left:15px; margin-right:15px;">
			<div style="font-weight:bold;"
				 [innerHTML]="message?.summary">
			</div>
			<div [innerHTML]="message?.detail">
			</div>
		</div>
	</ng-template>
</p-toast>

<router-outlet></router-outlet>

