// Angular Imports
import {Injectable} from '@angular/core';
import {StringHelpers} from '../../helpers/string-helpers';
import {LoaderService} from '../../components/loader/loader.service';
import {ObjectHelpers} from '../../helpers/object-helpers';
import {ConfirmationService, MessageService} from 'primeng/api';
import set = Reflect.set;
import {AlertService} from '../../components/alert/alert.service';

export type ToastPosition = 'top' | 'bottom' | 'middle';

@Injectable({
	providedIn: 'root'
})
export class NotificationsService {

	constructor(private _loaderService: LoaderService,
				private _messageService: MessageService,
				private _confirmService: ConfirmationService) {
	}

	public showLoader(loaderText: string = '', showConnectionWarning: boolean = true): void {
		this._loaderService.showLoader(StringHelpers.isNullOrWhiteSpace(loaderText) ? 'Loading... Please wait' : loaderText);
	}

	public hideLoader(): void {
		setTimeout(() => {
			this._loaderService.hideLoader();
		});
	}

	public showAlertDialog(accept: () => void, message: string, acceptLabel: string = 'Yes', acceptVisible: boolean = true, rejectLabel: string = 'No', rejectVisible: boolean = true) {
		this._confirmService.confirm({
			accept,
			message,
			acceptLabel,
			acceptVisible,
			rejectLabel,
			rejectVisible,
			icon: 'pi pi-exclamation-triangle'
		});
	}

	public showInfoDialog(accept: () => void, message: string, acceptLabel: string = 'Ok', acceptVisible: boolean = true, rejectLabel: string = 'No', rejectVisible: boolean = false) {
		this._confirmService.confirm({
			accept,
			message,
			acceptLabel,
			acceptVisible,
			rejectLabel,
			rejectVisible,
			icon: 'pi pi-info-circle'
		});
	}

	public showConfirmDialog(accept: () => void, message: string, acceptLabel: string = 'Yes', acceptVisible: boolean = true, rejectLabel: string = 'No', rejectVisible: boolean = true) {
		this._confirmService.confirm({
			accept: () => accept,
			message,
			acceptLabel,
			acceptVisible,
			rejectLabel,
			rejectVisible,
			icon: 'pi pi-check'
		});
	}

	public showErrorToastMessage(title: string, body: string, icon: string = 'pi-times-circle', duration: number = 4500): void {
		this._messageService.add({
			key: 'requiredFieldsToast',
			severity: 'error',
			summary: title,
			detail: body,
			life: duration,
			data: icon
		});
	}

	public showWarningToastMessage(title: string, body: string, icon: string = 'pi-exclamation-triangle', duration: number = 4500): void {
		this._messageService.add({
			key: 'requiredFieldsToast',
			severity: 'warn',
			summary: title,
			detail: body,
			life: duration,
			data: icon
		});
	}

	public showInfoToastMessage(title: string, body: string, icon: string = 'pi-info-circle', duration: number = 4000): void {
		this._messageService.add({
			key: 'requiredFieldsToast',
			severity: 'info',
			summary: title,
			detail: body,
			life: duration,
			data: icon
		});
	}

	public showSuccessToastMessage(title: string, body: string, icon: string = 'pi-check', duration: number = 2500): void {
		this._messageService.add({
			key: 'requiredFieldsToast',
			severity: 'success',
			summary: title,
			detail: body,
			life: duration,
			data: icon
		});
	}
}



