import {Injectable} from '@angular/core';
import {Logger} from '../logger/logger';
import {ObjectHelpers} from '../../helpers/object-helpers';
import {CacheType, CachingOptions} from './caching-options';

@Injectable({
	providedIn: 'root'
})
export class CacherService {

	private readonly _logger: Logger;
	private _noLogMessage: boolean = false;

	public constructor() {
		this._logger = new Logger('CacherService', true);
	}

	private initOptionDefaults(options: Partial<CachingOptions>): CachingOptions {
		const cachingOptions = new CachingOptions();
		if (!ObjectHelpers.isNullUndefinedOrEmpty(options)) {
			Object.assign(cachingOptions, options);
		}

		return cachingOptions;
	}

	public async countKeys(): Promise<number> {
		const cachedKeys: Array<string> = await this.getAllKeys();
		this._logger.logVerbose(`Cached entry count: `, cachedKeys.length);

		return cachedKeys.length;
	}

	public setData(key: string, value: any, options?: Partial<CachingOptions>): void {  // todo: start implementing caching-options class on services (wip)
		const cachingOptions = this.initOptionDefaults(options);
		const valueJson: string = JSON.stringify(value);

		if (cachingOptions.cacheType === CacheType.Local) {
			localStorage.setItem(key, valueJson);
		} else {
			sessionStorage.setItem(key, valueJson);
		}

		this._logger.logVerbose(`Creating new cache entry for key '${key}' with data: `, value);
	}

	public getByKey<TModel>(key: string, options?: Partial<CachingOptions>): Promise<TModel> {
		const cachingOptions = this.initOptionDefaults(options);

		return new Promise<TModel>(resolve => {
			let returnedObj: string | null;

			if (cachingOptions.cacheType === CacheType.Local) {
				returnedObj = localStorage.getItem(key);
			} else {
				returnedObj = sessionStorage.getItem(key);
			}

			if (!ObjectHelpers.isNullOrUndefined(returnedObj)) {
				if (!this._noLogMessage) {
					this._logger.logVerbose(`Found cache entry with key '${key}'. Retrieved data: `, returnedObj);
				}

				resolve(JSON.parse(returnedObj) as TModel);
			} else {
				if (!this._noLogMessage) {
					this._logger.logVerbose(`No cache entry could be found with key '${key}'.`);
				}

				resolve(undefined);
			}
		});
	}

	public getByKeyBasic<TModel>(key: string, options?: Partial<CachingOptions>): TModel {
		const cachingOptions = this.initOptionDefaults(options);

		let returnedObj: string | null;

		if (cachingOptions.cacheType === CacheType.Local) {
			returnedObj = localStorage.getItem(key);
		} else {
			returnedObj = sessionStorage.getItem(key);
		}

		if (!ObjectHelpers.isNullOrUndefined(returnedObj)) {
			if (!this._noLogMessage) {
				this._logger.logVerbose(`Found cache entry with key '${key}'. Retrieved data: `, returnedObj);
			}

			return (JSON.parse(returnedObj) as TModel);
		} else {
			if (!this._noLogMessage) {
				this._logger.logVerbose(`No cache entry could be found with key '${key}'.`);
			}

			return (undefined);
		}
	}

	public getAllKeys(options?: Partial<CachingOptions>): Array<string> {
		const cachingOptions = this.initOptionDefaults(options);
		const cachedKeys: Array<string> = [];

		if (cachingOptions.cacheType === CacheType.Local) {
			for (let i = 0; i < localStorage.length; i++) {
				const cachedKey = localStorage.key(i);
				cachedKeys.push(cachedKey);
			}
		} else {
			for (let i = 0; i < sessionStorage.length; i++) {
				const cachedKey = sessionStorage.key(i);
				cachedKeys.push(cachedKey);
			}
		}

		for (let i = 0; i < localStorage.length; i++) {
			const cachedKey = localStorage.key(i);
			cachedKeys.push(cachedKey);
		}

		this._logger.logVerbose(`Cached keys: `, cachedKeys);

		return cachedKeys;
	}

	public hasKey(key: string, options?: Partial<CachingOptions>): boolean {
		const cachingOptions = this.initOptionDefaults(options);
		this._noLogMessage = true;
		let value: string | null;

		if (cachingOptions.cacheType === CacheType.Local) {
			value = localStorage.getItem(key);
		} else {
			value = sessionStorage.getItem(key);
		}

		if (!ObjectHelpers.isNullUndefinedOrEmpty(value)) {
			this._noLogMessage = false;
			this._logger.logVerbose(`Cached key exists with name '${key}'. Value: `, true);
			return true;
		} else {
			this._noLogMessage = false;
			this._logger.logVerbose(`Cached key does not exist with name '${key}'. Value: `, false);
			return false;
		}
	}

	public loopKeyValuePairs(iteratorCallback: (value: any, key: string, iterationNumber: number) => any): void {
		const keys: Array<string> = this.getAllKeys();

		let index: number = 0;
		for (const key of keys) {
			const value: any = this.getByKey(key);
			iteratorCallback(value, key, index);
			index++;
		}
	}

	public removeByKey(key: string): void {
		this._logger.logVerbose(`Removing cache entry with key name: `, key);
		localStorage.removeItem(key);
	}

	public clearCache(): void {
		this._logger.logVerbose(`Clearing all cached entries.`);
		localStorage.clear();
	}

	public clearCacheExcluding(excludedKeys: Array<string>): void {
		this._logger.logVerbose(`Excluded cache entries from clear cache: `, excludedKeys);
		const cachedKeys: Array<string> = this.getAllKeys();

		for (const cachedKey of cachedKeys) {
			if (!ObjectHelpers.isNullUndefinedOrEmpty(excludedKeys)) {
				if (excludedKeys.findIndex(excludedKey => cachedKey.includes(excludedKey)) < 0) {
					this.removeByKey(cachedKey);
				} else {
					this._logger.logVerbose(`Cache entry with key name will be excluded from remove: `, cachedKey);
				}
			} else {
				this.removeByKey(cachedKey);
			}
		}
	}
}
