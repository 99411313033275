import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {StringHelpers} from '../../../shared/helpers/string-helpers';

@Component({
	selector: 'app-reset-password',
	templateUrl: './reset-password.component.html',
	styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
	public resetEmailForm: FormGroup;
	public emailAddress: string;

	@Output('isFormValid')
	public readonly formValid: EventEmitter<boolean> = new EventEmitter<boolean>();

	@Output()
	public readonly valueChange: EventEmitter<string> = new EventEmitter<string>();

	@Input()
	public set value(emailAddressToChange: string) {
		if (!StringHelpers.isNullOrWhiteSpace(emailAddressToChange)) {
			this.emailAddress = emailAddressToChange;
		}
	}

	constructor(private _formBuilder: FormBuilder) {
	}

	public ngOnInit(): void {
		this.initializeForm();
	}

	private initializeForm(): void {
		this.resetEmailForm = this._formBuilder.group({
			emailAddress: ['', Validators.compose([Validators.email, Validators.required])]
		});
	}

	public isFormValid(): boolean {
		return this.resetEmailForm.valid;
	}

	public async valueChanged(field: string): Promise<void> {
		const emailAddress = this.resetEmailForm.value as string;

		if (!StringHelpers.isNullOrWhiteSpace(emailAddress)) {
			this.populateFromForm(field);
			this.valueChange.emit(this.emailAddress);
		}

		this.formValid.emit(this.isFormValid());
	}

	private populateFromForm(field: string): void {
		if (field === 'emailAddress') {
			this.emailAddress = this.resetEmailForm.controls.emailAddress.value;
		}

	}

}
