import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ElementRef,
	HostListener,
	OnDestroy,
	OnInit,
	ViewChild
} from '@angular/core';
import {AuthService} from './pages/authentication/login/auth.service';
import {Router} from '@angular/router';
import {CacherService} from './pages/shared/services/cacher/cacher.service';
import {APP_VERSION} from './services/global/branch.service';
import {NotificationsService} from './pages/shared/services/notifications/notifications.service';
import toggleFullscreen from 'toggle-fullscreen';
import {ObjectHelpers} from './pages/shared/helpers/object-helpers';
import {ScrollPanel} from 'primeng/scrollpanel';
import {DialogService} from 'primeng/dynamicdialog';
import {InactiveLoginComponent} from './pages/authentication/login/inactive-login/inactive-login.component';
import {lastValueFrom, Subject} from 'rxjs';
import {PermissionService} from './pages/shared/services/permissions/permission.service';
import {UserInformation} from './pages/authentication/login/models/login-response.model';
import {RequestOptions} from './models/shared/request-options.model';
import {Globals} from "./pages/shared/globals";
import {map} from "rxjs/operators";
import {ICode} from "./models/settings/code";
import {ITenantSettings} from "./models/settings/tenant-settings";
import {StringHelpers} from "./pages/shared/helpers/string-helpers";
import {SettingsService} from "./services/settings/settings.service";
import {IGeneralSettingsResponse} from "./models/settings/general-settings-response";

@Component({
	selector: 'app-main',
	templateUrl: './app.main.component.html',
})

export class AppMainComponent implements AfterViewInit, OnDestroy, OnInit {
	public layoutContainer: HTMLDivElement;
	public menuClick: boolean;
	public rotateMenuButton: boolean;
	public topbarMenuActive: boolean;
	public staticMenuDesktopInactive: boolean;
	public staticMenuMobileActive: boolean;
	public menuHoverActive: boolean;
	public topbarItemClick: boolean;
	public activeTopbarItem: any;
	public disableMenu: boolean = false;
	public appVersion: string = '';
	public pageHeading: string = '';
	public emptyCodesList: string[] = [];
	public showMessage: boolean = false;

	@ViewChild('layoutContainer') layourContainerViewChild: ElementRef;
	@ViewChild('scrollPanel') layoutMenuScrollerViewChild: ScrollPanel;

	public screenHeight: number;
	public screenWidth: number;
	private userActivity: any;
	public isPopupDisplayed: boolean = false;
	private userInactive: Subject<any> = new Subject();

	constructor(private _dialogService: DialogService,
				private _authService: AuthService,
				private _router: Router,
				private _cacheService: CacherService,
				private changeDetectorRef: ChangeDetectorRef,
				private _permissionService: PermissionService,
				private _notificationsService: NotificationsService,
				private _settingsService: SettingsService) {

		this.getScreenSize();
		this.screenHeight = window.innerHeight;
		this.screenWidth = window.innerWidth;

		this.logUserOutIfInActive();
		this.userInactive.subscribe();
	}

	public ngAfterViewInit(): void {
		this.layoutContainer = this.layourContainerViewChild.nativeElement as HTMLDivElement;
		setTimeout(() => {
			this.layoutMenuScrollerViewChild.moveBar();
		}, 100);
		this.changeDetectorRef.detectChanges();
	}

	public ngOnDestroy(): void {
		this.userInactive.unsubscribe();
	}

	public async ngOnInit(): Promise<void> {
		await this.getGeneralSettings();
		await this.getAppVersion();

	}


	public async getGeneralSettings(): Promise<void> {

		try {
			this.emptyCodesList = new Array<string>();
			const generalSettingsCache = await this._cacheService.getByKey<IGeneralSettingsResponse>('GeneralSettings');

			if (!ObjectHelpers.isNullUndefinedOrEmpty(generalSettingsCache)) {

				const codes: ICode[] = generalSettingsCache.codes.filter(code => code.entityCodeName !== 'PurchaseInvoiceNumber');
				const tenantSettings: ITenantSettings[] = generalSettingsCache.tenantSettings.filter(item => item.id === 'PrintingPurchaseOrderLabel' || item.id === 'SellerDeclarationLabel');

				this.setChangeListName(codes, tenantSettings);
			} else {
				await this.getGeneralSettingByAPI();
			}
		} catch (e) {
			console.error('Error getting general settings: ' + e);
		}
	}

	public setChangeListName(codes: ICode[], tenantSettings: ITenantSettings[]): void {
		if (!ObjectHelpers.isNullUndefinedOrEmpty(codes) && codes.length > 0) {
			codes.forEach(code => {

				if (StringHelpers.isNumberNullOrUndefined(code.number)) {
					this.changeListName(code.entityCodeName);
				}
			});
		}

		if (!ObjectHelpers.isNullUndefinedOrEmpty(tenantSettings) && tenantSettings.length > 0) {
			tenantSettings.forEach(setting => {
				if (StringHelpers.isNullOrWhiteSpace(setting.setting)) {
					this.changeListName(setting.id);
				}
			});
		}

		this.showMessage = this.emptyCodesList.length > 0;
	}

	private async getGeneralSettingByAPI(): Promise<void> {
		const getAllGeneralSettings$ = this._settingsService.getGeneralSettings(
			Globals.BranchIds[0], new RequestOptions()
		).pipe(map(res => res.singleResult));

		await lastValueFrom(getAllGeneralSettings$).then((generalSettings) => {

			if (!ObjectHelpers.isNullUndefinedOrEmpty(generalSettings)) {
				const codes: ICode[] = generalSettings.codes.filter(code => code.entityCodeName !== 'PurchaseInvoiceNumber');
				const tenantSettings: ITenantSettings[] = generalSettings.tenantSettings.filter(item => item.id === 'PrintingPurchaseOrderLabel' || item.id === 'SellerDeclarationLabel');

				this.setChangeListName(codes, tenantSettings);

				this._cacheService.removeByKey('GeneralSettings');
				this._cacheService.setData('GeneralSettings', generalSettings);
			}
		}).catch(() => {
			console.error('Error getting general settings');
		});
	}
	private changeListName(codeName: string): void {

		let nameForList: string;

		switch (codeName) {
			case 'OrderCode' :
				nameForList = 'Order Code';
				break;
			case 'StockCode' :
				nameForList = 'Stock Code';
				break;
			case 'InvoiceCode' :
				nameForList = 'Invoice Code';
				break;
			case 'ClientCode' :
				nameForList = 'Client Code';
				break;
			case 'SupplierCode' :
				nameForList = 'Supplier Code';
				break;
			case 'PrintingPurchaseOrderLabel' :
				nameForList = 'Printing Purchase Order';
				break;
			case 'SellerDeclarationLabel' :
				nameForList = 'Sellers Declaration Label';
				break;
			default :
				nameForList = '';
		}

		if (!StringHelpers.isNullOrWhiteSpace(nameForList)) {
			this.emptyCodesList.push(nameForList);
		}
	}


	private async getAppVersion(): Promise<void> {
		if (this._cacheService.hasKey(APP_VERSION)) {

		}
	}

	@HostListener('window:resize', ['$event'])
	public getScreenSize(event?): void {
		this.screenHeight = window.innerHeight;
		this.screenWidth = window.innerWidth;
	}

	public onMenuButtonClick(event): void {
		this.menuClick = true;
		this.rotateMenuButton = !this.rotateMenuButton;
		this.topbarMenuActive = false;

		if (this.checkDeviceSize('isDesktop')) {
			this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
		} else {
			this.staticMenuMobileActive = !this.staticMenuMobileActive;
		}

		event.preventDefault();
	}

	public onLayoutClick(): void {
		if (!this.topbarItemClick) {
			this.activeTopbarItem = null;
			this.topbarMenuActive = false;
		}

		this.menuHoverActive = false;
		this.topbarItemClick = false;
	}

	public onTopbarItemClick(event, item): void {
		this.topbarItemClick = true;

		if (this.activeTopbarItem === item) {
			this.activeTopbarItem = null;
		} else {
			this.activeTopbarItem = item;
		}

		event.preventDefault();
	}

	public checkDeviceSize(deviceType: string) : boolean {
		switch (deviceType) {
			case 'isMobile':
				return window.innerWidth <= 640;
			case 'isDesktop':
				return window.innerWidth >= 1024;
			case 'isTablet':
				const width = window.innerWidth;
				return width < 1024 && width > 640;
		}
	}

	public async logout(): Promise<void> {

		this._permissionService.flushRolesAndPermissions();
		this._authService.logout();
		await this._router.navigate(['/login']);
	}

	// detects if mouse hasn't moved over a hour
	@HostListener('window:mousemove')
	@HostListener('window:keydown')
	@HostListener('window:click')
	@HostListener('window:scroll')
	public refreshUserState(): void {
		clearTimeout(this.userActivity);
		this.logUserOutIfInActive();
	}

	// log user out after 1 hour
	public logUserOutIfInActive(): void {
		const logoutTime: number = 3600000;

		clearTimeout(this.userActivity);

		if (!this.isPopupDisplayed) {
			this.userActivity = setTimeout(() => {
					this.showLogoutWarning();
			}, logoutTime);
		}
	}

	public showLogoutWarning(): void {

		if (this._router.url !== '/login') {

			this.isPopupDisplayed = true;

			const ref = this._dialogService.open(InactiveLoginComponent,
				{
					header: `Inactive User`,
					data: {},
					closeOnEscape: false,
					closable: false,
					width: this.getModalWidth()
				});
			ref.onClose.subscribe(async () => {

				this.refreshUserState();
				this.isPopupDisplayed = false;
			});
		}
	}

	public async switchCompany(): Promise<void> {
		const allBranches = await this._cacheService.getByKey<UserInformation>('AllBranches');

		if (ObjectHelpers.isNullUndefinedOrEmpty(allBranches)) {
			this._notificationsService.showWarningToastMessage('Notification', 'Unable to switch companies as only one active company is available.');
		} else {
			await this._router.navigate(['/company-select']);
		}
	}

	public async maximizeWindow(): Promise<void> {
		const docElem: HTMLElement = document.documentElement;
		await toggleFullscreen(docElem)
		.catch((err) => {
			console.error('Failed to go into Full Screen view mode. Error: ', err);
		});
	}

	public refreshPage(): void {
		location.reload();
	}

	public getUnlockFeedModalWidth(): string {
		switch (true) {
			case this.checkDeviceSize('isMobile'):
				return '200px';
			case this.checkDeviceSize('isTablet'):
				return '300px';
			case this.checkDeviceSize('isDesktop'):
				return '400px';
			default:
				return '400px'; // Default value if none of the cases match
		}
	}

	public getModalWidth(): string {
		let modalWidth: string = '400px';

		switch (true) {
			case this.checkDeviceSize('isMobile'):
				modalWidth = '360px';
				break;
			case this.checkDeviceSize('isTablet'):
				modalWidth = '450px';
				break;
			case this.checkDeviceSize('isDesktop'):
				modalWidth = '600px';
				break;
		}

		return modalWidth;
	}
}
