export enum CacheType {
	Local,
	Session
}

export class CachingOptions {

	public cacheType: CacheType;

	constructor() {
		this.cacheType = CacheType.Local;
	}

}
