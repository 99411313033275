<body>
	<div class="parent-container"
		 [ngClass]="{'search-active': searchActive}">
		<div class="company-container">
			<div class="company-toolbar grid justify-content-between">
				<div class="logo-container">
					<img src="../../../../assets/layout/images/logo2x_2.png" style="max-width:160px;">
				</div>
				<div class="buttons-container">
					<a class="search-button"
					   style="display:flex; align-items:center;"
					   pTooltip="Search companies"
					   (click)="searchToggle()">
						<i class="pi pi-search" style="font-size:1.8em;"></i>
					</a>
					<button pButton
							class="logout-button"
							type="button"
							icon="pi pi-sign-in"
							style="font-size:1.1em !important;"
							(click)="logout()"
							pTooltip="Return to login">
					</button>
				</div>
			</div>
			<div class="company-content-container">
				<div class="grid align-content-center vertical-container justify-content-center">
					<div class="col-fixed company-header">
						Select a company
					</div>
					<div class="pcol-fixed company" *ngFor="let company of this.companyBranchFeatures | filter: companySearch"
						 (click)="onCompanySelect(company)">
						<p-card [style]="setBackgroundImage(company)"></p-card>
						<span class="company-name"> {{company.companyName}}</span>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="search-container grid" [ngClass]="{'active': searchActive, 'inactive': !searchActive}">
		<div class="col-12" style="text-align:right; margin-top:15px">
			<div (click)="searchToggle()">
				<i class="pi pi-times" style="font-size:1.5em; color:#fff; cursor:pointer"></i>
			</div>
		</div>
		<div class="col-12" style="text-align:center">
			<div class="search-input ui-inputgroup"
				 [ngClass]="{'active': searchActive, 'inactive': !searchActive}">
					<input pInputText
						   type="text"
						   (click)="$event.target.select()"
						   name="dealerSearch"
						   [(ngModel)]="companySearch"
						   autocomplete="off"
						   placeholder="Search..."
						   (keyup)="enterPressed($event)">
					<button pButton (click)="clearInput()" icon="pi pi-times" class="clear-button"></button>
					<div class="search-message" style="margin-right:52px;">Hit Enter to close.
					</div>
			</div>
		</div>
	</div>
</body>

