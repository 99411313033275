// Angular Imports
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { from, Observable, of, throwError } from 'rxjs';
import {catchError, switchMap, tap, timeout} from 'rxjs/operators';

import {RequestOptions} from '../../models/shared/request-options.model';
import {CacherService} from '../../pages/shared/services/cacher/cacher.service';
import {StringHelpers} from '../../pages/shared/helpers/string-helpers';
import {Globals} from '../../pages/shared/globals';
import {Logger} from '../../pages/shared/services/logger/logger';
import {ObjectHelpers} from '../../pages/shared/helpers/object-helpers';
import {NotificationsService} from '../../pages/shared/services/notifications/notifications.service';
import {Router} from '@angular/router';


export class ErrorResponse {
	public statusCode: number;
	public status: string;
	public error: string;
	public additionalErrors: Array<string>;
	public isSuccessful: boolean;
	public requestUrl: string;
}

@Injectable({
	providedIn: 'root'
})
export class ApiService {

	private _defaults: RequestOptions = new RequestOptions();

	constructor(private _http: HttpClient,
				private _notificationsService: NotificationsService,
				private _cacher: CacherService,
				private _router: Router
	) {
	}

	public apiRequest<TResponse>(endpointUrl: string, requestBody: any, options: RequestOptions = new RequestOptions()): Observable<TResponse> {
		const settings: RequestOptions = {
			...this._defaults,
			...options
		};

		if (settings.useCache) {
			if (StringHelpers.isNullOrWhiteSpace(settings.cacheKeyName)) {
				return throwError('[API] "cacheKeyName" cannot be null or empty when using "useCache" option on ApiService.');
			}

			Logger.logVerbose(`[API ${settings.method.toUpperCase()}] Cache Key Name: `, settings.cacheKeyName);
			return of({}).pipe(
				tap(() => this.showLoader(settings)),
				switchMap(() => {
					const hasKey: boolean = this._cacher.hasKey(settings.cacheKeyName);
					Logger.logVerbose(`[API ${settings.method.toUpperCase()}] Cache Key Exists: `, hasKey);
					if (hasKey) {
						Logger.logDebug(`[API] "${settings.cacheKeyName}" loaded from cache.`);
						return from(this._cacher.getByKey<TResponse>(settings.cacheKeyName));
					} else {
						return this.sendRequest<TResponse>(endpointUrl, requestBody, settings);
					}
				}),
				tap(() => this.hideLoader(settings)),
				timeout(settings.timeout),
				catchError(error => {
					this.hideLoader(settings);
					const errorResponse: ErrorResponse = this.getErrorResponse(error);
					Logger.logError(`[API ${settings.method.toUpperCase()}] Error: `, errorResponse);
					return throwError(() => errorResponse);
				})
			);
		}

		return this.sendRequest<TResponse>(endpointUrl, requestBody, settings);
	}

	private sendRequest<TResponse>(endpointUrl: string, requestBody: any, settings: RequestOptions): Observable<TResponse> {
		const url = endpointUrl ? `${settings.baseUrl}/${endpointUrl}` : settings.baseUrl;
		return of({}).pipe(
			tap(() => {
				if (StringHelpers.isNullOrWhiteSpace(Globals.AuthToken) && settings.useToken) {
					return throwError(`[API] User is not authenticated. Current Authentication Token value: "${Globals.AuthToken}"`);
				}
			}),
			tap(() => this.showLoader(settings)),
			switchMap(() => from(this.getRequestParams(settings, requestBody))),
			switchMap((value: {headers: HttpHeaders, body: any}) => {
				return this._http.request<TResponse>(settings.method, url,
					{headers: value.headers, body: value.body});
			}),
			tap<TResponse>(async (response: TResponse) => {
				if (settings.useCache && settings.method === 'get') {
					await this._cacher.setData(settings.cacheKeyName, response);
					Logger.logVerbose(`[API ${settings.method.toUpperCase()}] Setting cache for key "${settings.cacheKeyName}". Value: `, response);
				}
			}),
			tap(() => this.hideLoader(settings)),
			timeout(settings.timeout),
			catchError(error => {
				this.hideLoader(settings);
				const errorResponse: ErrorResponse = this.getErrorResponse(error);

				if (errorResponse.statusCode === 401) {
					this._router.navigate(['/login']).then();
					this._notificationsService.hideLoader();
				}
				Logger.logError(`[API ${settings.method.toUpperCase()}] Error: `, errorResponse);
				return throwError(() => errorResponse);
			})
		);
	}

	private async getRequestParams<TResponse>(settings: RequestOptions, requestBody: any): Promise<{headers: HttpHeaders, body: any} | Observable<never>> {
		let headers: HttpHeaders = new HttpHeaders({
			Accept: 'application/json'
		});

		headers = headers.append('use-caching', settings.useCaching.toString());

		if (settings.useToken) {
			if (!StringHelpers.isNullOrWhiteSpace(Globals.AuthToken)) {
				headers = headers.append('Authorization', `Bearer ${Globals.AuthToken}`);

				if (!StringHelpers.isNullOrWhiteSpace(Globals.CompanyId.toString()))
					headers = headers.append('company-id', Globals.CompanyId.toString());

			} else {
				return throwError(`[API] "useToken" option is set to true,
				 but Authentication Token value is empty or null. Current value: "${Globals.AuthToken}"`);
			}
		}

		let body: any;
		if (!ObjectHelpers.isNullOrUndefined(requestBody)) {
			body = requestBody;
		}

		return {headers, body};
	}

	private getErrorResponse(err: HttpErrorResponse): ErrorResponse {
		const message: string = this.getServerError(err);
		return {
			status: 			err.statusText,
			statusCode: 		err.status,
			error: 				message,
			additionalErrors: 	this.getAdditionalErrors(err),
			isSuccessful: 		err.ok,
			requestUrl: 		err.url
		};
	}

	private getServerError(err: HttpErrorResponse): string {
		if (!ObjectHelpers.isNullOrUndefined(err.error) && ObjectHelpers.isObject(err.error)) {
			if ('Messages' in err.error) {
				return err.error['Messages'][0];
			} else {
				return err.message;
			}
		} else {
			return err.message;
		}
	}

	private getAdditionalErrors(err: HttpErrorResponse): Array<string> {
		if (!ObjectHelpers.isNullOrUndefined(err.error) && ObjectHelpers.isObject(err.error)) {
			if ('AdditionalMessages' in err.error) {
				return err.error['AdditionalMessages'] as Array<string>;
			}
		}

		return undefined;
	}

	private showLoader(settings: RequestOptions): void {
		if (settings.showLoader) {
			this._notificationsService.showLoader(settings.loaderText);
		}
	}

	private hideLoader(settings: RequestOptions): void {
		if (settings.showLoader) {
			this._notificationsService.hideLoader();
		}
	}
}
