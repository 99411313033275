import {Component, OnInit} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {ObjectHelpers} from '../../helpers/object-helpers';

@Component({
	selector: 'app-alert',
	templateUrl: './alert.component.html',
	styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {
	public header: string;
	public content: string;


	constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) {
	}

	ngOnInit() {
		if (!ObjectHelpers.isNullUndefinedOrEmpty(this.config.data)){
			this.header = this.config.header;
			this.content = this.config.data.content;
		}
	}

}
