export class StringHelpers {

	public static isNullOrWhiteSpace(value: string): boolean {
		return value === null || value === undefined || value.length === 0 || /^\s*$/.test(value);
	}

	public static isNumberNullOrUndefined(value: number): boolean {
		return value === null || value === undefined;
	}

	public static convertVersionToNumber(version: string): number {
		const regex: RegExp = /\./g;
		return parseInt(version.replace(regex, ''), 10);
	}

	public static removeAllWhitespaces(value: string): string {
		const regex: RegExp = /\s/g;
		return value.replace(regex, '');
	}

	public static addLeadingChars(value: number, paddingChar: string, totalLength: number): string {
		const zero: number = totalLength - value.toString().length + 1;
		return Array(+(zero > 0 && zero)).join(paddingChar) + value;
	}

}
