import { Injectable } from '@angular/core';
import {GenericEntityService} from "../shared/generic-entity.service";
import {ApiService} from "../shared/api.service";
import {CacherService} from "../../pages/shared/services/cacher/cacher.service";
import {NotificationsService} from "../../pages/shared/services/notifications/notifications.service";
import {Globals} from "../../pages/shared/globals";
import {IReport} from "../../models/reports/ireport";
import {IReportingTypeUI} from "../../models/reports/IReportTypeUI";
import {ResponseWrapper} from "../../models/IResponseWrapper";
import {map} from "rxjs/operators";
import {Observable} from "rxjs";
import {RequestOptions} from "../../models/shared/request-options.model";

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

	constructor(protected apiService: ApiService,
				protected cacherService: CacherService,
				protected notificationsService: NotificationsService){}

	public getAllReports(requestOptions: RequestOptions) : Observable<ResponseWrapper<IReport[]>> {

		requestOptions.method = 'get';
		const endpoint: string = 'all?branchId=' + Globals.BranchIds[0];
		requestOptions.baseUrl = Globals.ReportApiBaseUrl;

		return this.apiService.apiRequest<ResponseWrapper<IReport[]>>(endpoint, '', requestOptions);
	}
}
