import {Injectable} from '@angular/core';
import {NgxPermissionsService, NgxRolesService} from 'ngx-permissions';
import {ObjectHelpers} from '../../helpers/object-helpers';
import {EmployeeAndCompany} from '../../../authentication/login/models/login-response.model';

@Injectable({
	providedIn: 'root'
})
export class PermissionService {

	constructor(private _permissionService: NgxPermissionsService,
				private _roleService: NgxRolesService) {
	}

	public addRoleWithPermissions(userInformation: EmployeeAndCompany): void {

		if (!ObjectHelpers.isNullUndefinedOrEmpty(userInformation.systemUser)) {

			const permissions = new Set(userInformation.userLevelPermissions);
			permissions.add(userInformation.systemUser.userLevel);
			this._roleService.addRoleWithPermissions(userInformation.systemUser.userLevel, Array.from(permissions));
		}
	}

	public flushRolesAndPermissions(): void {
		this._roleService.flushRolesAndPermissions();
	}


	public async validatePermission(permissions: string[]): Promise<boolean> {
		if (!ObjectHelpers.isNullUndefinedOrEmpty(permissions)) {
			return await this._permissionService.hasPermission(permissions);
		}
	}
}
