<div class="grid p-fluid login-page" [formGroup]="loginForm">
	<div class="field col-12 padding">
                <span class="p-float-label">
                    <input formControlName="emailAddress"
						   type="email"
						   class="input"
						   pInputText
						   (click)="$event.target.select()"
						   (ngModelChange)="valueChanged('emailAddress')"
						   (keyup.enter)="loginWithKeypress()">
                    <label>Username</label>
                </span>
	</div>
	<div class="field col-12 padding">
		<div class="p-inputgroup">
			<span class="p-float-label">
				<input [type]="passwordType"
					   formControlName="password"
					   class="input"
					   pInputText
					   (click)="$event.target.select()"
					   (ngModelChange)="valueChanged('password')"
					   (keyup)="checkCapsLock($event)"
					   (keyup.enter)="loginWithKeypress()">
				<label>Password</label>
			</span>
			<span class="p-inputgroup-addon" style="cursor: pointer">
				<div class="col-2" style="padding-top: 0;padding-bottom: 0;" (click)="togglePassword()">
					<label *ngIf="showPassword" class="fa fa-eye"></label>
					<label *ngIf="!showPassword" class="fa fa-eye-slash"></label>
				</div>
			</span>
		</div>
		<span *ngIf="capsLock" class="caps-warning"><i class="ui-icon-lock" style="vertical-align:baseline;font-size:0.7rem;"></i> CAPS LOCK is On.</span>
	</div>
</div>
