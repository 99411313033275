/* tslint:disable:no-console */
import {ObjectHelpers} from '../../helpers/object-helpers';
import {StringHelpers} from '../../helpers/string-helpers';

export enum LogLevel {
	Verbose = 0,
	Debug = 1,
	Information = 2,
	Warning = 3,
	Error = 4
}

export class Logger {

	constructor(typeName: string, useTypeName: boolean) {
		this._typeName = typeName;
		this._useTypeName = useTypeName;
	}

	private static _minimumLogLevel: LogLevel = LogLevel.Information;
	private readonly _typeName: string = '';
	private readonly _useTypeName: boolean = false;

	public static initialize(options: { minLogLevel: LogLevel }): void {
		if (!ObjectHelpers.isNullOrUndefined(options)) {
			this._minimumLogLevel = options.minLogLevel;
		} else {
			throw new Error('Cannot initialize the Logger without specifying the options parameter.');
		}
	}

	public static log(logLevel: LogLevel, message: string, ...optionalParams: Array<any>): void {
		if (logLevel === LogLevel.Verbose) {
			this.logVerbose(message, optionalParams);
		} else if (logLevel === LogLevel.Debug) {
			this.logDebug(message, optionalParams);
		} else if (logLevel === LogLevel.Information) {
			this.logInformation(message, optionalParams);
		} else if (logLevel === LogLevel.Warning) {
			this.logWarning(message, optionalParams);
		} else {
			this.logError(message, optionalParams);
		}
	}

	public static logVerbose(message: string, ...optionalParams: Array<any>): void {
		if (this._minimumLogLevel > LogLevel.Verbose) {
			return;
		}

		if (!ObjectHelpers.isNullUndefinedOrEmpty(optionalParams)) {
			console.trace(message, ...optionalParams);
		} else {
			console.trace(message);
		}
	}

	public static logDebug(message: string, ...optionalParams: Array<any>): void {
		if (this._minimumLogLevel > LogLevel.Debug) {
			return;
		}

		if (!ObjectHelpers.isNullUndefinedOrEmpty(optionalParams)) {
			console.debug(message, ...optionalParams);
		} else {
			console.debug(message);
		}
	}

	public static logInformation(message: string, ...optionalParams: Array<any>): void {
		if (this._minimumLogLevel > LogLevel.Information) {
			return;
		}

		if (!ObjectHelpers.isNullUndefinedOrEmpty(optionalParams)) {
			console.log(message, ...optionalParams);
		} else {
			console.log(message);
		}
	}

	public static logWarning(message: string, ...optionalParams: Array<any>): void {
		if (this._minimumLogLevel > LogLevel.Warning) {
			return;
		}

		if (!ObjectHelpers.isNullUndefinedOrEmpty(optionalParams)) {
			console.warn(message, ...optionalParams);
		} else {
			console.warn(message);
		}
	}

	public static logError(message: string, ...optionalParams: Array<any>): void {
		if (this._minimumLogLevel > LogLevel.Error) {
			return;
		}

		if (!ObjectHelpers.isNullUndefinedOrEmpty(optionalParams)) {
			console.error(message, ...optionalParams);
		} else {
			console.error(message);
		}
	}

	public log(logLevel: LogLevel, message: string, ...optionalParams: Array<any>): void {
		if (logLevel === LogLevel.Verbose) {
			this.logVerbose(message, optionalParams);
		} else if (logLevel === LogLevel.Debug) {
			this.logDebug(message, optionalParams);
		} else if (logLevel === LogLevel.Information) {
			this.logInformation(message, optionalParams);
		} else if (logLevel === LogLevel.Warning) {
			this.logWarning(message, optionalParams);
		} else {
			this.logError(message, optionalParams);
		}
	}

	public logVerbose(message: string, ...optionalParams: Array<any>): void {
		if (Logger._minimumLogLevel > LogLevel.Verbose) {
			return;
		}

		if (!ObjectHelpers.isNullUndefinedOrEmpty(optionalParams)) {
			console.trace(this.getMessage(message), ...optionalParams);
		} else {
			console.trace(this.getMessage(message));
		}
	}

	public logDebug(message: string, ...optionalParams: Array<any>): void {
		if (Logger._minimumLogLevel > LogLevel.Debug) {
			return;
		}

		if (!ObjectHelpers.isNullUndefinedOrEmpty(optionalParams)) {
			console.debug(this.getMessage(message), ...optionalParams);
		} else {
			console.debug(this.getMessage(message));
		}
	}

	public logInformation(message: string, ...optionalParams: Array<any>): void {
		if (Logger._minimumLogLevel > LogLevel.Information) {
			return;
		}

		if (!ObjectHelpers.isNullUndefinedOrEmpty(optionalParams)) {
			console.log(this.getMessage(message), ...optionalParams);
		} else {
			console.log(this.getMessage(message));
		}
	}

	public logWarning(message: string, ...optionalParams: Array<any>): void {
		if (Logger._minimumLogLevel > LogLevel.Warning) {
			return;
		}

		if (!ObjectHelpers.isNullUndefinedOrEmpty(optionalParams)) {
			console.warn(this.getMessage(message), ...optionalParams);
		} else {
			console.warn(this.getMessage(message));
		}
	}

	public logError(message: string, ...optionalParams: Array<any>): void {
		if (Logger._minimumLogLevel > LogLevel.Error) {
			return;
		}

		if (!ObjectHelpers.isNullUndefinedOrEmpty(optionalParams)) {
			console.error(this.getMessage(message), ...optionalParams);
		} else {
			console.error(this.getMessage(message));
		}
	}

	private getMessage(message: string): string {
		if (this._useTypeName) {
			if (!StringHelpers.isNullOrWhiteSpace(this._typeName)) {
				return `[${this._typeName}] ${message}`;
			} else {
				return message;
			}
		} else {
			return message;
		}
	}

}
