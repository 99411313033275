import {ModuleWithProviders} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AppMainComponent} from './app.main.component';
import {AuthGuard} from './pages/authentication/login/auth.guard';
import {LoginComponent} from './pages/authentication/login/login.component';
import {CompanySelectComponent} from './pages/authentication/company-select/company-select.component';
import {ReportsComponent} from './pages/modules/reports/reports.component';
import {NgxPermissionsGuard} from 'ngx-permissions';
import {
	CompanySelectAuthGuard
} from './pages/authentication/company-select/company-guard/company-select-auth-guard.guard';


const routes: Routes = [
	{
		path: 'login', component: LoginComponent
	},
	{
		path: 'company-select', component: CompanySelectComponent, canActivate: [CompanySelectAuthGuard]
	},
	{
		path: '', component: AppMainComponent,
		children: [
			{path: '', loadChildren: () => import('./pages/modules/stock-management/stock-management.module').then(m => m.StockManagementModule), canActivate: [AuthGuard]},
			{path: 'stock-management', loadChildren: () => import('./pages/modules/stock-management/stock-management.module').then(m => m.StockManagementModule), canActivate: [AuthGuard]},
			{path: 'settings', loadChildren: () => import('./pages/modules/settings/settings.module').then(m => m.SettingsModule), canActivate: [AuthGuard]},
			{path: 'company', loadChildren: () => import('./pages/modules/company-settings/company.module').then(m => m.CompanyModule), canActivate: [AuthGuard]},
			{path: 'profile', loadChildren: () => import('./components/profile/profile.module').then(m => m.ProfileModule), canActivate: [AuthGuard]},
			{path: 'reports', component: ReportsComponent,
				canActivate: [AuthGuard, NgxPermissionsGuard],
				data: {
					permissions: {
						only: ['Admin', 'Reports'],
						redirectTo: 'stock-management'
					}
				}},
			{path: 'suppliers', loadChildren: () => import('./pages/modules/suppliers/suppliers.module').then(m => m.SuppliersModule), canActivate: [AuthGuard]},
			{path: 'clients', loadChildren: () => import('./pages/modules/clients/clients.module').then(m => m.ClientsModule), canActivate: [AuthGuard]},
		]
	}
];

export const AppRoutingModule: ModuleWithProviders<any> = RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'});
