import {AfterViewInit, ChangeDetectorRef, Component, ViewChild} from '@angular/core';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {IClient} from '../../../../models/global/client';
import {ColumnsModel} from '../../../../models/dealership/columns/columns.model';
import {Table} from 'primeng/table';
import {ObjectHelpers} from '../../helpers/object-helpers';
import {ClientService} from '../../../../services/global/client.service';
import {IClientInfoResponse, IClientSearch} from '../../../../models/global/payloads/client-search';
import {Globals} from '../../globals';
import {RequestOptions} from '../../../../models/shared/request-options.model';
import {lastValueFrom} from 'rxjs';
import {map} from 'rxjs/operators';
import {Chips} from 'primeng/chips';

@Component({
	selector: 'app-client-search',
	templateUrl: './client-search.component.html',
	styleUrls: ['./client-search.component.scss']
})
export class ClientSearchComponent implements AfterViewInit {
	@ViewChild('dt') dt: Table;
	@ViewChild('chipsInput') chipsInput: Chips;

	public clientSearchItems: Array<string> = new Array<string>();
	public loading: boolean = false;

	public selectedClient: IClient;
	public clients: Array<IClient> = new Array<IClient>();

	public clientsInfoResponse: Array<IClientInfoResponse> = new Array<IClientInfoResponse>();
	public columns: Array<ColumnsModel> = new Array<ColumnsModel>();

	constructor(protected _clientService: ClientService,
				private cd: ChangeDetectorRef,
				public ref: DynamicDialogRef) {}



	ngAfterViewInit(): void {
		this.setChipsFocus();
		this.cd.detectChanges();
	}

	private setChipsFocus(): void {
		const inputElement = this.chipsInput.inputViewChild.nativeElement;
		inputElement.focus();
	}

	public onRowSelect($event: any): void {
		if ($event.type === 'row') {
			this.selectedClient = $event.data;
		}
	}


	public close(): void {
		this.ref.close();
	}

	public populate(selectedClient: IClient): void {

		if (!ObjectHelpers.isNullUndefinedOrEmpty(selectedClient) &&
			!ObjectHelpers.isNullUndefinedOrEmpty(this.clientsInfoResponse)) {

			const client = this.clientsInfoResponse.find(x => x.client.id === selectedClient.id);

			if (!ObjectHelpers.isNullUndefinedOrEmpty(client))
			this.ref.close(client);
		}
	}

	private async getClient(search: Array<string>): Promise<void> {
		if (!ObjectHelpers.isNullUndefinedOrEmpty(search)) {

			const clientSearch: IClientSearch = {
				keywords: this.clientSearchItems,
				deleted: false,
				branchId: Globals.BranchIds[0]
			};

			const searchClients$ = this._clientService.searchClients(clientSearch, new RequestOptions())
				.pipe(map(x => x.multipleResult));

			await lastValueFrom(searchClients$).then(searchClients => {
				this.clients = new Array<IClient>();
				this.clientsInfoResponse = new Array<IClientInfoResponse>();

				if (!ObjectHelpers.isNullUndefinedOrEmpty(searchClients))
					this.clientsInfoResponse = searchClients;

				searchClients.forEach(client => {
						this.clients.push(client.client);
					});
			});
		}
	}

	public async searchClient(): Promise<void> {
		await this.getClient(this.clientSearchItems);
	}
}
