<div class="grid" [formGroup]="resetEmailForm">

	<div class="col-12 md:col-12 lg:col-12 padding">
                <span>
					Please enter the email address you would like to send the password change request to below:
                </span>
	</div>

	<div class="col-12 md:col-12 lg:col-12 padding">
                <span class="p-float-label">
                    <input formControlName="emailAddress"
						   type="text"
						   (click)="$event.target.select()"
						   pInputText
						   (ngModelChange)="valueChanged('emailAddress')">
                    <label>Email Address</label>
                </span>
	</div>
</div>
