<div class="multi-report-viewer" *ngIf="reports">
	<p-tabView id="closableTabView"
			   (onChange)="onTabChange($event)" [controlClose]="true"
			   (onClose)="onTabClose($event)" [(activeIndex)]="selectedReportIndex"
			   [scrollable]="showScrollButtons">
		<p-tabPanel *ngFor="let report of reports; let i = index" [selected]="i === selectedReportIndex" [closable]="true">
			<ng-template pTemplate="header">
				<span>{{  report.reportName.length > 14 ? report.reportName.substring(0, 14) + '..' :  report.reportName }}</span>
			</ng-template>
			<tr-viewer *ngIf="i === selectedReportIndex"
					   [containerStyle]="viewerContainerStyle"
					   [serviceUrl]="baseUrl"
					   [reportSource]="report"
					   [viewMode]="'PRINT_PREVIEW'"
					   [scaleMode]="'FIT_PAGE_WIDTH'"
					   [authenticationToken]="reportDetails['Token']"
					   [scale]="1.0">
			</tr-viewer>
		</p-tabPanel>
	</p-tabView>
</div>
