import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AppMainComponent} from '../../../app.main.component';
import {map} from 'rxjs/operators';
import {lastValueFrom, Observable} from 'rxjs';
import {UserInformation} from '../../authentication/login/models/login-response.model';
import {Globals} from '../../shared/globals';
import {IReport} from '../../../models/reports/ireport';
import {NumberHelpers} from '../../shared/helpers/number-helpers';
import {ReportPreviewerComponent} from '../../shared/components/report-previewer/report-previewer.component';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {CacherService} from '../../shared/services/cacher/cacher.service';
import {ReportsService} from '../../../services/reports/reports.service';
import {RequestOptions} from '../../../models/shared/request-options.model';
import {
	EnumReportTypes,
	IReportConfig,
	TDateReportConfig,
	TDefaultReportConfigs
} from '../../../models/reports/ireport-config';
import {ResponseWrapper} from '../../../models/IResponseWrapper';
import {HttpParams} from '@angular/common/http';
import {ObjectHelpers} from '../../shared/helpers/object-helpers';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit, OnDestroy {

	public reportsSearchInput: string;
	public allReports: Array<IReport> = new Array<IReport>();
	public reportTypes: Set<string> = new Set<string>();
	public reportList: Array<string> = new Array<string>();
	public selectedReportType: string = 'Stock';
	public selectedReportList: string;
	public dateRange: Date[] = [];
	public userInformation: UserInformation;

	public viewerContainerStyle = {
		position: 'relative',
		width: '100%',
		height: '74vh',
		['font-family']: 'ms sans serif'
	};
	public baseUrl: string = Globals.APIReportsUrl;
	public reportConfig: IReportConfig;
	public reportDetails: any;
	public defaultParameters: any;
	public companyId: string;
	public expandReportDialog: DynamicDialogRef;
	public disablePeriodRange: boolean = true;
	public showIsVat: boolean = true;
	public isVat: boolean = false;

	constructor(private _appMainComponent: AppMainComponent,
				private _dialogService: DialogService,
				private _cacheService: CacherService,
				public _reportsService: ReportsService) {
		this._appMainComponent.pageHeading = 'Reports';
	}

	public async ngOnInit() : Promise<void> {

		this.setInitialDateRange();

		this.companyId = await this._cacheService.getByKey<string>('CompanyId');
		this.userInformation = await this._cacheService.getByKey<UserInformation>('UserInformation');

		await this.getAllReports();
	}

	public ngOnDestroy() : void {
		this.expandReportDialog?.close();
	}

	public setReportConfig(parameter: any) : void {
		 this.reportConfig = {
			report: `${this.selectedReportList}.trdp`,
			parameters: parameter
		};
	}

	public previewReport() : void {

		try {
			if (!ObjectHelpers.isNullUndefinedOrEmpty(this.userInformation)) {
				this.defaultParameters = {
					UserId: this.userInformation.user.id,
					Token: this.userInformation.accessToken,
					CompanyId: this.companyId,
					BranchId: Globals.BranchIds[0],
				};

				switch (this.selectedReportList) {
					case EnumReportTypes.StockListBasicWithLicenseAndVinNumber:
						this.disablePeriodRange = true;
						this.showIsVat = false;

						this.setReportConfig({
							...this.setDefaultReportConfigs()
						});
						break;
					case EnumReportTypes.StockListBasic:
						this.disablePeriodRange = true;
						this.showIsVat = false;

						this.setReportConfig({
							...this.setDefaultReportConfigs()
						});
						break;
					case EnumReportTypes.StockListRegisterOf2ndHandGoodsByDate :
						this.disablePeriodRange = false;
						this.showIsVat = false;

						this.setReportConfig({
							...this.setDefaultReportConfigs(),
							...this.setDateReportConfig()
						});
						break;
					case EnumReportTypes.StockListDaysInStock:
						this.disablePeriodRange = true;
						this.showIsVat = false;

						this.setReportConfig({
							...this.setDefaultReportConfigs()
						});
						break;
					case EnumReportTypes.StockListOTPListDateRange :
						this.disablePeriodRange = false;
						this.showIsVat = false;

						this.setReportConfig({
							...this.setDefaultReportConfigs(),
							...this.setDateReportConfig()
						});
						break;
					case EnumReportTypes.StockListNotInvoicedManagerwithEstimatedProfit:
						this.disablePeriodRange = true;
						this.showIsVat = true;
						this.setReportConfig({
							...this.setDefaultReportConfigs(),
							VatInclude: this.isVat,
						});
						break;
					case EnumReportTypes.StockListWithReconCostsWithSelectedDate:
						this.disablePeriodRange = false;
						this.showIsVat = true;
						this.setReportConfig({
							...this.setDefaultReportConfigs(),
							IsVat: this.isVat,
							StartDate: this.setDateReportConfig().StartDate,
						});
						break;
					case EnumReportTypes.FinanceListFullProfitVatExclusiveAndInclusiveReport :
						this.disablePeriodRange = false;
						this.showIsVat = true;

						this.setReportConfig({
							...this.setDefaultReportConfigs(),
							...this.setDateReportConfig(),
							IsVat: this.isVat,
						});
						break;
					case EnumReportTypes.FinanceListFullProfitBySalesSourceVatExclusiveAndInclusive :
						this.disablePeriodRange = false;
						this.showIsVat = true;

						this.setReportConfig({
							...this.setDefaultReportConfigs(),
							...this.setDateReportConfig(),
							IsVat: this.isVat,
						});
						break;
					case EnumReportTypes.FinanceListFullProfitBySalesPersonVatExclusiveAndInclusive :
						this.disablePeriodRange = false;
						this.showIsVat = true;

						this.setReportConfig({
							...this.setDefaultReportConfigs(),
							...this.setDateReportConfig(),
							IsVat: this.isVat,
						});
						break;
					case EnumReportTypes.FinancialReportsSalesmansClientsReportForStockSold :
						this.disablePeriodRange = false;

						this.setReportConfig({
							...this.setDefaultReportConfigs(),
							...this.setDateReportConfig(),
						});
						break;
					default :
						this.reportConfig = {};
				}
			}
		} catch(e) {
			console.error('Cannot preview report: ' + e);
		}
	}

	private setDefaultReportConfigs(): TDefaultReportConfigs {
		return {
			UserId: this.defaultParameters.UserId,
			CompanyId: this.defaultParameters.CompanyId,
			BranchId: Globals.BranchIds[0]
		};
	}

	private setDateReportConfig(): TDateReportConfig {
		return {
			StartDate: this.dateRange[0].getFullYear() + '-' + (this.dateRange[0].getMonth() + 1) + '-' + this.dateRange[0].getDate(),
			EndDate: this.dateRange[1].getFullYear() + '-' + (this.dateRange[1].getMonth() + 1) + '-' + this.dateRange[1].getDate()
		};
	}

	public async expandReport() : Promise<void> {
		try {
			if (!ObjectHelpers.isNullUndefinedOrEmpty(this.userInformation)) {
				this.expandReportDialog = this._dialogService.open(ReportPreviewerComponent, {
					header: this.selectedReportList,
					data: {
						parameters: this.reportConfig.parameters,
						reportDetails: {
							Token:  this.userInformation.accessToken,
							ReportName: this.selectedReportList
						}
					},
					closeOnEscape: true,
					closable: true,
				});
			}
		} catch (e) {
			console.error('Cannot expand report: ' + e);
		}
	}

	public async getAllReports() : Promise<void> {
		const getAllReports$ : Observable<IReport[]> = this._reportsService.getAllReports(new RequestOptions())
			.pipe(map((res: ResponseWrapper<IReport[]>) => res.multipleResult));

		await lastValueFrom(getAllReports$).then(reports => {

			this.allReports = reports;

			reports.forEach(item => {

				this.reportTypes.add(item.templateType);
				if (item.templateType === 'Stock'){
					this.reportList.push(item.description);
				}
			});

			this.selectedReportList = this.reportList[0];
			this.previewReport();
		}).catch((e) => {
			console.error('could not get reports ' , e);
		});
	}

	public changeReportList() : void {

		this.reportList = new Array<string>();

		const newReportList : IReport[] = this.allReports.filter(item => item.templateType === this.selectedReportType);

		newReportList.forEach(item => {
			this.reportList.push(item.description);
		});
	}

	public numbersOnly = (event: any): boolean => NumberHelpers.numbersOnly(event);

	public searchReportList() : void {

		this.reportList = new Array<string>();

		const filteredReportsBySearch : IReport[] = this.allReports.filter(item => item.description.toLowerCase().includes(this.reportsSearchInput.toLowerCase()));
		filteredReportsBySearch.forEach(item => {
			this.reportList.push(item.description);
		});
	}

	public openReportInNewTab(): void {

		const params : HttpParams = new HttpParams()
			.set('report', this.reportConfig.report)
			.set('parameters', JSON.stringify(this.reportConfig.parameters))
			.set('reportName', this.selectedReportList);

		const reportUrl: string = '/assets/reportTemplate.html?' + params.toString();
		window.open(reportUrl, '_blank');
	}

	private setInitialDateRange() : void {
		const today = new Date();
		this.dateRange[0] = new Date(today.getFullYear(), today.getMonth(), 1);
		this.dateRange[1] = new Date(today.getFullYear(), today.getMonth() + 1, 0);
	}
}
