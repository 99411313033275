import { Injectable } from '@angular/core';
import {GenericEntityService} from '../shared/generic-entity.service';
import {ISystemUser} from '../../models/auth/system-user';
import {ApiService} from '../shared/api.service';
import {CacherService} from '../../pages/shared/services/cacher/cacher.service';
import {NotificationsService} from '../../pages/shared/services/notifications/notifications.service';
import {Globals} from '../../pages/shared/globals';
import {ObjectHelpers} from '../../pages/shared/helpers/object-helpers';
import * as _ from 'underscore';
@Injectable({
  providedIn: 'root'
})
export class SystemUserService extends GenericEntityService<ISystemUser, string> {

	constructor(protected apiService: ApiService,
				protected cacherService: CacherService,
				protected notificationsService: NotificationsService
	) {
		const baseUrl: string = Globals.DealershipAuthApiBaseUrl + '/SystemUsers';
		super(notificationsService, apiService, baseUrl);
	}

	private readonly UserProfileCacheKey: string = 'UserInformation';

	public async getSystemUserProfileFromCache(): Promise<void> {
		if (await this.cacherService.hasKey(this.UserProfileCacheKey)) {
			const systemUser = await this.cacherService.getByKey<ISystemUser>(this.UserProfileCacheKey);
			if (!ObjectHelpers.isNullOrUndefined(systemUser)) {
				Globals.SystemUserProfile = _.clone(systemUser);
			}
		}
	}
}
