import {Component, OnInit} from '@angular/core';
import {AppMainComponent} from '../../app.main.component';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {AuthService} from '../../pages/authentication/login/auth.service';
import {Router} from '@angular/router';
import {Globals} from '../../pages/shared/globals';

import {ObjectHelpers} from '../../pages/shared/helpers/object-helpers';
import {ISystemUser} from '../../models/auth/system-user';
import {SystemUserService} from '../../services/auth/system-user.service';
import {lastValueFrom, Observable} from "rxjs";
import {IEmployee} from "../../models/auth/employee";
import {RequestOptions} from "../../models/shared/request-options.model";
import {map} from "rxjs/operators";
import {EmployeeService} from "../../pages/modules/company-settings/services/employee.service";

@Component({
	selector: 'app-profile',
	templateUrl: './profile.component.html',
	animations: [
		trigger('menu', [
			state('hidden', style({
				height: '0px'
			})),
			state('visible', style({
				height: '*'
			})),
			transition('visible => hidden', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
			transition('hidden => visible', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
		])
	],
	styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
	public active: boolean;
	public systemUserProfile: ISystemUser;
	public global = Globals;

	constructor(public app: AppMainComponent,
				private _systemUserService: SystemUserService,
				private _employeeService: EmployeeService,
				private _authService: AuthService,
				private _router: Router) {
	}

	async ngOnInit(): Promise<void> {
		await this._systemUserService.getSystemUserProfileFromCache();
		this.systemUserProfile = Globals.SystemUserProfile;

	}

	public setProfileImageUrl(element: HTMLImageElement): void {
		if (!ObjectHelpers.isNullUndefinedOrEmpty(element)) {
			element.src = '../../assets/layout/images/avatar.png';
		}
	}

	onClick(event) {
		this.active = !this.active;
		setTimeout(() => {
			this.app.layoutMenuScrollerViewChild.moveBar();
		}, 450);
		event.preventDefault();
	}

	public async logout(): Promise<void> {
		await this._authService.logout();
		await this._router.navigate(['/login']);
	}

	public async editProfile(): Promise<void> {
		const employees$: Observable<IEmployee[]> = this._employeeService.getAll(new RequestOptions({showLoader: true}))
			.pipe(map(res => res.multipleResult));

		await lastValueFrom(employees$).then(async (employees: IEmployee[]) => {
			const currentUser = employees.find(x => x.systemUserId === this.systemUserProfile.id);

			await this._router.navigateByUrl(`/company/employees/edit/${currentUser.id}`);
		});
	}

}
