export class NumberHelpers {

	public static numbersOnly(event): boolean {
		const charCode = (event.which) ? event.which : event.keyCode;
		if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 45 && charCode !== 46) {
			return false;
		}
		return true;
	}
}
