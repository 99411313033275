import {Component} from '@angular/core';
import {BranchService} from './services/global/branch.service';
import {Logger, LogLevel} from './pages/shared/services/logger/logger';
import {environment} from '../environments/environment';
import {Globals} from './pages/shared/globals';
import {CacherService} from './pages/shared/services/cacher/cacher.service';
import {IAddress} from './models/global/address';
import {ObjectHelpers} from './pages/shared/helpers/object-helpers';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent {
	title = 'vmgonline-web';

	constructor(private _branchesService: BranchService,
				private _cacherService: CacherService) {
		this.initialize().then();
	}

	public async initialize(): Promise<void> {

		if (environment.type === 'production' || environment.type === 'staging') {
			Logger.initialize({minLogLevel: LogLevel.Warning});
		} else {
			Logger.initialize({minLogLevel: LogLevel.Debug});
		}

		await this._branchesService.getBranchCacheValues();

		this._cacherService.getByKey('CompanyAddress').then((res: IAddress) => {
			if (!ObjectHelpers.isNullUndefinedOrEmpty(res)) {
				Globals.RegionId = res.regionId;
			}
		});
	}
}
