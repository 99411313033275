<div class="layout-wrapper layout-compact" (click)="onLayoutClick()" >
	<div #layoutContainer class="layout-container"
		 [ngClass]="{'menu-layout-static':true,
       'layout-menu-static-inactive': staticMenuDesktopInactive || disableMenu,
       'layout-menu-static-active': staticMenuMobileActive}">
		<app-topbar [pageHeading]="pageHeading"></app-topbar>

		<div class="layout-menu layout-menu-dark" >
			<p-scrollPanel #scrollPanel  >
				<app-profile></app-profile>
				<app-menu></app-menu>

			</p-scrollPanel>
		</div>

		<div class="layout-main grid vertical-container"
			 [ngClass]="
				 {
				 'mobile-footer-height': this.checkDeviceSize('isMobile'),
				 'tablet-footer-height': this.checkDeviceSize('isTablet'),
				 'desktop-footer-height': this.checkDeviceSize('isDesktop')
				 }">
			<div class="col-12 relative" style="height: calc(100% - 40px); padding-left: 0;">
				<div class="absolute z-5" style="right: 4px;top: 0px">
					<p-messages severity="warn" closable="true" *ngIf="showMessage" styleClass="py-0 w-full" [enableService]="false" >
						<ng-template pTemplate="content">
							<div class="flex flex-wrap align-items-center w-full">
								<div class="col-1">
									<i class="pi pi-exclamation-triangle" style="font-size: 40px"></i>
								</div>
								<div class="col-10">
									<div class="flex flex-wrap align-items-center">
										<div class="col-12 p-0">
											<p class="m-0">
												<strong>Warning!</strong> Some required fields are missing for <strong>General Settings</strong>:
											</p>
										</div>
										<div class="col-12 p-0">
											<ul class="m-1 list-disc" >
												<li *ngFor="let code of emptyCodesList">{{code}}</li>
											</ul>
										</div>
									</div>
								</div>
								<div class="col-1 text-right">
									<i class="pi pi-times cursor-pointer" (click)="showMessage = false"></i>
								</div>
							</div>
						</ng-template>
					</p-messages>
				</div>
				<router-outlet></router-outlet>
			</div>
		</div>
		<div class="layout-mask"></div>
	</div>
</div>
