// Angular Imports
import {Injectable} from '@angular/core';

import {Observable, of, throwError} from 'rxjs';
import {catchError, delay, mergeMap, switchMap, tap, timeout} from 'rxjs/operators';

import {CacherService} from '../../shared/services/cacher/cacher.service';
import {NotificationsService} from '../../shared/services/notifications/notifications.service';
import {ObjectHelpers} from '../../shared/helpers/object-helpers';
import {Logger} from '../../shared/services/logger/logger';
import {Globals} from '../../shared/globals';
import {ApiService, ErrorResponse} from '../../../services/shared/api.service';

import {LoginRequestModel} from './models/login-request.model';
import {JwtHelperService} from '@auth0/angular-jwt';
import {StringHelpers} from '../../shared/helpers/string-helpers';
import {ChangePasswordModel} from '../models/change-password';
import {IEmployee} from '../../../models/auth/employee';
import {ISsoUserToken} from '../../../models/auth/sso-user-token';
import {RequestOptions} from '../../../models/shared/request-options.model';
import {IRefreshTokenPayload} from '../../../models/auth/refresh-token-payload';
import {UserInformation} from './models/login-response.model';

const jwtHelperService = new JwtHelperService();


@Injectable({
	providedIn: 'root'
})
export class AuthService {

	public loginResponse: UserInformation;

	constructor(private _apiService: ApiService,
				private _cacherService: CacherService,
				private _notificationsService: NotificationsService
	) {
	}

	private readonly UserIdCacheKey: string = 'UserId';
	private readonly AuthTokenCacheKey: string = 'AuthToken';
	private readonly RefreshTokenCacheKey: string = 'RefreshToken';


	private static setAuthGlobalValues(userId: string, authToken: string, refreshToken: string, employee: IEmployee): void {
		Globals.UserId = userId;
		Globals.AuthToken = authToken;
		Globals.RefreshToken = refreshToken;
		Globals.Employee = employee;
	}

	public login(loginRequest: LoginRequestModel): Observable<UserInformation> {
		if (ObjectHelpers.isNullUndefinedOrEmpty(loginRequest)) {
			Logger.logError(`[LOGIN] Email Address and Password was not specified.`);
			return throwError(`Email Address and Password was not specified.`);
		}

		const loginPageRequest$: Observable<UserInformation> = this._apiService.apiRequest<UserInformation>('login', loginRequest, {
			baseUrl: Globals.AuthApiBaseUrl,
			method: 'post',
			timeout: 30000,
			showLoader: true,
			useToken: false
		});

		return of({}).pipe(
			mergeMap(() => loginPageRequest$),
			timeout(30000),
			catchError(error => {
				this._notificationsService.hideLoader();
				Logger.logError(`[LOGIN] Login failed with the specified user credentials. Error: `, error);
				this.getAuthErrorResponse(error);
				return throwError(error);
			})
		);
	}
	public refreshToken(refreshToken: IRefreshTokenPayload, requestOptions: RequestOptions): Observable<ISsoUserToken> {
		if (ObjectHelpers.isNullUndefinedOrEmpty(refreshToken)) {
			Logger.logError(`[REFRESH TOKEN] Refresh Token specified cannot be null or empty.`);
			return throwError(`Refresh Token specified cannot be null or empty.`);
		}

		requestOptions.method = 'post';
		const endpoint: string = 'refreshToken';
		requestOptions.baseUrl = Globals.AuthApiBaseUrl;
		return this._apiService.apiRequest<ISsoUserToken>(endpoint, refreshToken, requestOptions);
	}

	public resetPassword(email: any): Observable<void> {
		if (ObjectHelpers.isNullOrUndefined(email)) {
			Logger.logError(`[RESET PASSWORD] Email specified cannot be null or empty.`);
			return throwError(`Email specified cannot be null or empty.`);
		}

		const resetPasswordRequest$: Observable<void> = this._apiService.apiRequest<void>(`reset-password`, email, {
			baseUrl: Globals.AuthApiBaseUrl,
			method: 'post',
			timeout: 30000,
			showLoader: true,
			useToken: false
		});

		return of({}).pipe(
			mergeMap(() => resetPasswordRequest$),
			timeout(30000),
			catchError(error => {
				this._notificationsService.hideLoader();
				Logger.logError(`[RESET PASSWORD] Failed to reset password. Error: `, error);
				return throwError(error);
			})
		);
	}

	public changePassword(changePasswordModel: ChangePasswordModel): Observable<void> {
		if (ObjectHelpers.isNullOrUndefined(changePasswordModel)) {
			Logger.logError(`[CHANGE PASSWORD] User Id and/or Password specified cannot be null or empty.`);
			return throwError(`User Id and/or Password specified cannot be null or empty.`);
		}

		const changePasswordRequest$: Observable<void> = this._apiService.apiRequest<void>(`change-password`, changePasswordModel, {
			baseUrl: Globals.AuthApiBaseUrl,
			method: 'post',
			timeout: 30000,
			showLoader: true,
			useToken: false
		});

		return of({}).pipe(
			mergeMap(() => changePasswordRequest$),
			timeout(30000),
			catchError(error => {
				this._notificationsService.hideLoader();
				Logger.logError(`[CHANGE PASSWORD] Failed to change password. Error: `, error);
				return throwError(error);
			})
		);
	}

	public async setAuthCacheValues(userId: string, authToken: string, refreshToken: string, employee: IEmployee): Promise<void> {
		AuthService.setAuthGlobalValues(userId, authToken, refreshToken, employee);
	}

	private async getAuthErrorResponse(errorResponse: ErrorResponse): Promise<void> {
		if (errorResponse.statusCode >= 400 && errorResponse.statusCode < 500) {
			await this._notificationsService.showErrorToastMessage('Unauthorized', errorResponse.error);
		} else {
			await this._notificationsService.showErrorToastMessage('Alert', 'Login failed due to an error on the server. ' +
				'Please try again and if the problem persists, please contact support.');
		}
	}

	// public register(registerRequest: RegisterUserModel): Observable<RegisterUserModel> {
	// 	if (ObjectHelpers.isNullUndefinedOrEmpty(registerRequest)) {
	// 		Logger.logError(`[Register] Register failed.`);
	// 		return throwError(`Email Address and Password was not specified.`);
	// 	}
	//
	// 	const registerRequest$: Observable<RegisterUserModel> = this._apiService.apiRequest<RegisterUserModel>('Register', registerRequest, {
	// 		baseUrl: Globals.AuthApiBaseUrl,
	// 		method: 'post',
	// 		timeout: 30000,
	// 		showLoader: false,
	// 		useToken: true
	// 	}).pipe(
	// 		tap(async (response: RegisterUserModel) => {
	// 			if (ObjectHelpers.isNullUndefinedOrEmpty(response)) {
	// 				return throwError('Failed to retrieve a register response from the server.');
	// 			}
	//
	// 			// await this.setAuthCacheValues(response.userId, response.accessToken, response.refreshToken);
	// 		})
	// 	);
	//
	// 	return of({}).pipe(
	// 		mergeMap(() => registerRequest$),
	// 		timeout(30000),
	// 		catchError(error => {
	// 			this._notificationsService.hideLoader();
	// 			Logger.logError(`[Register] Register failed. Error: `, error);
	// 			this.getAuthErrorResponse(error);
	// 			return throwError(error);
	// 		})
	// 	);
	// }


	public logout(): void {
		AuthService.setAuthGlobalValues('', '', '', null);
		this._cacherService.clearCache();
	}

	public decodeToken(token: string): any {
		return jwtHelperService.decodeToken(token);
	}

	public getTokenExpirationDate(token: string): Date | null {
		return jwtHelperService.getTokenExpirationDate(token);
	}

	isTokenExpired(token: string, offsetSeconds?: number): boolean {
		return jwtHelperService.isTokenExpired(token, offsetSeconds);
	}

}
