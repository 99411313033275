import { Component } from '@angular/core';
import {DynamicDialogRef} from "primeng/dynamicdialog";
import {StringHelpers} from "../../helpers/string-helpers";

@Component({
  selector: 'app-advert-report-selection',
  templateUrl: './advert-report-selection.component.html',
  styleUrls: ['./advert-report-selection.component.scss']
})
export class AdvertReportSelectionComponent {

	constructor(private ref: DynamicDialogRef) {
	}

	public setReportName(header: string, reportName: string): void {

		if (!StringHelpers.isNullOrWhiteSpace(reportName) && !StringHelpers.isNullOrWhiteSpace(header)) {
            const reportData = {
                header: header,
                reportName: reportName
            };
            this.ref.close(reportData);
		}
	}

}
