import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { RequestOptions } from '../../models/shared/request-options.model';
import { NotificationsService } from '../../pages/shared/services/notifications/notifications.service';
import {ResponseWrapper} from '../../models/IResponseWrapper';
import {map} from 'rxjs/operators';
import {IGenericModel} from '../../models/shared/generic-model';
import {IGetAllPayload} from "../../models/shared/get-all-payload";

@Injectable({
	providedIn: 'root'
})
export abstract class GenericEntityService<TEntity extends IGenericModel<TKey>, TKey> {
	private readonly baseUrl: string;
	public data: TEntity[] = [];

	protected constructor(protected notificationService: NotificationsService,
						  protected apiService: ApiService,
						  protected _baseUrl: string) {
		this.baseUrl = _baseUrl;
	}

	public getAll(requestOptions: RequestOptions): Observable<ResponseWrapper<TEntity[]>> {
		requestOptions.method = 'get';
		const endpoint: string = requestOptions.endpointUrl;
		requestOptions.baseUrl = requestOptions.baseUrl ? requestOptions.baseUrl : this.baseUrl;

		return this.apiService.apiRequest<ResponseWrapper<TEntity[]>>(endpoint, null, requestOptions)
			.pipe(
				map((response: ResponseWrapper<TEntity[]>) => {
					this.data = response.multipleResult;
					return response;
				})
			);
	}

	public getAllSearch(payload: IGetAllPayload, requestOptions: RequestOptions): Observable<ResponseWrapper<TEntity[]>> {
		requestOptions.method = 'post';
		const endpoint: string = requestOptions.endpointUrl + '/search';
		requestOptions.baseUrl = requestOptions.baseUrl ? requestOptions.baseUrl : this.baseUrl;

		return this.apiService.apiRequest<ResponseWrapper<TEntity[]>>(endpoint, payload, requestOptions)
			.pipe(
				map((response: ResponseWrapper<TEntity[]>) => {
					this.data = response.multipleResult;
					return response;
				})
			);
	}

	public getById(id: TKey, requestOptions: RequestOptions): Observable<ResponseWrapper<TEntity>> {
		requestOptions.method = 'get';
		const endpoint: string = requestOptions.endpointUrl ? `${requestOptions.endpointUrl}/${id}` : `${id}`;
		requestOptions.baseUrl = this.baseUrl;
		return this.apiService.apiRequest<ResponseWrapper<TEntity>>(endpoint, null, requestOptions);
	}

	public create(payload: TEntity, requestOptions: RequestOptions): Observable<ResponseWrapper<TEntity>> {
		requestOptions.method = 'post';
		const endpoint: string = requestOptions.endpointUrl;
		requestOptions.baseUrl = this.baseUrl;
		return this.apiService.apiRequest<ResponseWrapper<TEntity>>(endpoint, payload, requestOptions);
	}

	public update<TEntity>(payload: TEntity, requestOptions: RequestOptions): Observable<ResponseWrapper<TEntity>> {
		requestOptions.method = 'put';
		const endpoint: string = requestOptions.endpointUrl;
		requestOptions.baseUrl = this.baseUrl;
		return this.apiService.apiRequest<ResponseWrapper<TEntity>>(endpoint, payload, requestOptions);
	}

	public delete(payload: TEntity, requestOptions: RequestOptions): Observable<ResponseWrapper<TEntity>> {
		requestOptions.method = 'delete';
		const endpoint: string = requestOptions.endpointUrl;
		requestOptions.baseUrl = this.baseUrl;
		return this.apiService.apiRequest<ResponseWrapper<TEntity>>(endpoint, payload, requestOptions)
		  .pipe(
			map((response: ResponseWrapper<TEntity>) => {
			  const index = this.data.findIndex(item => item.id === payload.id);
			  if (index !== -1) {
				this.data.splice(index, 1);
			  }
			  return response;
			})
		  );
	  }

	public bulkMerge<TEntity>(payload: TEntity[], requestOptions: RequestOptions): Observable<ResponseWrapper<TEntity>> {
		requestOptions.method = 'post';
		const endpoint: string = 'BulkMerge';
		requestOptions.baseUrl = this.baseUrl;
		return this.apiService.apiRequest<ResponseWrapper<TEntity>>(endpoint, payload, requestOptions);
	}

	public bulkCreate<TEntity>(payload: TEntity[], requestOptions: RequestOptions): Observable<ResponseWrapper<TEntity>> {
		requestOptions.method = 'post';
		const endpoint: string = 'BulkCreate';
		requestOptions.baseUrl = this.baseUrl;
		return this.apiService.apiRequest<ResponseWrapper<TEntity>>(endpoint, payload, requestOptions);
	}

	public bulkUpdate<TEntity>(payload: TEntity[], requestOptions: RequestOptions): Observable<ResponseWrapper<TEntity>> {
		requestOptions.method = 'put';
		const endpoint: string = 'BulkUpdate';
		requestOptions.baseUrl = this.baseUrl;
		return this.apiService.apiRequest<ResponseWrapper<TEntity>>(endpoint, payload, requestOptions);
	}

	public bulkDelete<TEntity>(payload: TEntity[], requestOptions: RequestOptions): Observable<ResponseWrapper<TEntity>> {
		requestOptions.method = 'delete';
		const endpoint: string = 'BulkDelete';
		requestOptions.baseUrl = this.baseUrl;
		return this.apiService.apiRequest<ResponseWrapper<TEntity>>(endpoint, payload, requestOptions);
	}
}
