import {StringHelpers} from './string-helpers';

export class ObjectHelpers {
	public static sort(entity: Array<any>) {
		entity.sort((a, b) => {
			if (a.value < b.value) {
				return -1;
			}
			if (a.value > b.value) {
				return 1;
			}
			return 0;
		});
	}

	public static isNullOrUndefined(obj: any): boolean {
		return obj === null || obj === undefined;
	}

	public static isNullUndefinedOrEmpty(obj: any): boolean {
		if (obj === null || obj === undefined) {
			return true;
		}

		if (obj instanceof Array || Array.isArray(obj)) {
			if (obj.length === 0) {
				return true;
			}
		} else {
			return ObjectHelpers.isObjectEmpty(obj);
		}

		if (typeof obj === 'string' || obj instanceof String) {
			return StringHelpers.isNullOrWhiteSpace(obj.toString());
		}

		return false;
	}

	public static isObject(obj: any): boolean {
		return (typeof obj === 'object');
	}

	private static isObjectEmpty(object: any): boolean {
		for (const key in object) {
			if (object.hasOwnProperty(key)) {
				return false;
			}
		}
		return true;
	}

	public static cleanEntityModel<TEntity>(entity: TEntity): TEntity {
		if (!ObjectHelpers.isNullUndefinedOrEmpty(entity)) {
			for (const key in entity) {
				if (entity.hasOwnProperty(key) && key.indexOf('@odata') > -1) {
					delete entity[key];
				}
			}
		}

		return entity;
	}

	public static functionExists(object: any, name: string): boolean {
		if (ObjectHelpers.isNullUndefinedOrEmpty(object)) {
			return false;
		}
		return object[name] !== undefined;
	}
}
