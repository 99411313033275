<!--ClickACar url start-->
<div *ngIf="clickaCar; else vmgOnlineServices">
	<div class="background-video">
		<img src="../../../../assets/layout/images/CAC_LOGIN_BG.png">
	</div>
	<div class="grid align-content-center vertical-container justify-content-center" style="height: 98vh; overflow: auto">
		<div class="flex">
			<div class="card login-panel fluid"
				 style="height: 450px !important;width:450px;background-color: black;border-radius: 15px 0px 0px 15px;padding: 20px">
				<div>
					<h3 style="color: white;margin: 1px">
						The
					</h3>
					<img src="../../../../assets/layout/images/CAC_LOGIN_CONNECTLOGO.png" alt="clickacar">
					<h3 style="color: white;margin: 1px">Welcomes</h3>
				</div>
				<div class="col-12 md:col-12 lg:col-12" style="padding: 0">
					<img src="../../../../assets/layout/images/CAC_LOGIN_CACLOGO.png" width="250px" height="150px"
						 style="object-fit: contain;">
				</div>
				<div>
					<h5 style="color: white;margin: 1px;text-transform:uppercase;font-size: 14px;font-weight: 400;">
						Please sign in to access your
						<span style="color:#2DD972">
						clickacar web portal
					</span>
					</h5>
				</div>
				<div style="margin-bottom: 20px">
					<h5 style="color:#2DD972; margin: 15px 5px 5px 5px;">
						Need Support?
					</h5>
					<div>
				<span>
					<a style="color: #2DD972; font-size: 14px" href="tel:0877026300">
					<i style="margin-right: 5px" class="fa fa-phone"></i>
					087 702 6300
				</a>
					<a style="color: #2DD972; font-size: 14px;margin-left: 15px;" href="mailto:clickacar@vmgsoftware.co.za">
					<i style="margin-right: 5px" class="fa fa-envelope"></i>
					clickacar@vmgsoftware.co.za
				</a>
				</span>
					</div>
				</div>
				<div style="margin-bottom: 20px">
					<div>
				<span>
					<a style="color: #2DD972; font-size: 14px;margin-left: 15px;text-decoration: underline" href="https://www.clickacar.co.za/dealership">
						Register now for ClickACar
				</a>
				</span>
					</div>
				</div>
			</div>
		</div>
		<div class="p-fixed">
			<div class="card login-panel p-fluid" style="height: 450px;padding: 20px; border-radius: 0px 15px 15px 0px;">

				<div class="col-12" style="padding: 0">
					<img src="../../../../assets/layout/images/CAC_LOGIN_VMGLOGO.png" width="250px" height="150px"
						 style="object-fit: contain;">
				</div>

				<div *ngIf="loginFormActive;else show_reset_form">
					<app-log-on (isFormValid)="isLoginFormValid($event)" (loginWithEnter)="login()"
								[(value)]="loginDetails"></app-log-on>
				</div>


				<ng-template #show_reset_form>
					<app-reset-password (isFormValid)="isResetPasswordFormValid($event)"
										[(value)]="emailAddressToReset"></app-reset-password>
				</ng-template>

				<div *ngIf="loginFormActive" class="col-12">
					<button pButton type="button" label="Sign In" style="width: 100%" icon="ui-icon-person"
							(click)="login()"></button>
				</div>

				<div class="col-12" *ngIf="loginFormActive">
					<button pButton type="button" label="Forgot Password" style="width: 100%" class="green-btn"
							icon="ui-icon-help" (click)="forgotPassword()"></button>
				</div>

				<div class="col-12" *ngIf="!loginFormActive">
					<button pButton type="button" label="Reset Password" style="width: 100%"
							icon="ui-icon-send" (click)="resetPassword()"></button>
				</div>

				<div class="col-12" *ngIf="!loginFormActive">
					<button class="secondary-btn" pButton type="button" label="Return to login" style="width: 100%"
							icon="ui-icon-reply"
							(click)="returnToLogin()">
					</button>
				</div>
			</div>
		</div>
	</div>
	<div class="login-support">
		<span>National Landline:	087 702 6300 | Support:	Support@vmgsoftware.co.za</span>
	</div>
</div>
<!--ClickACar url end-->
<!--VMG Online Services url start-->
<ng-template #vmgOnlineServices>
	<video autoplay muted [loop]="true" class="background-video">
		<source src="../../../../assets/layout/images/background.mp4" type="video/mp4">
	</video>
	<div class="grid align-content-center vertical-container justify-content-center" style="height: 98vh; overflow: auto">
		<div class="col-fixed">
			<div class="card login-panel p-fluid" style="height: 450px; border-radius: 15px;animation: shadow-pulse 3s infinite;">

				<div class="col-12" style="padding: 0">
					<img src="../../../../assets/layout/images/VMG-LOGO.png" width="250px" height="150px" style="object-fit: contain;">
				</div>

				<div *ngIf="loginFormActive;else show_reset_form">
					<app-log-on (isFormValid)="isLoginFormValid($event)" (loginWithEnter)="login()" [(value)]="loginDetails"></app-log-on>
				</div>

				<ng-template #show_reset_form>
					<app-reset-password (isFormValid)="isResetPasswordFormValid($event)" [(value)]="emailAddressToReset"></app-reset-password>
				</ng-template>

				<div *ngIf="loginFormActive" class="col-12">
					<button pButton type="button" label="Sign In" style="width: 100%" icon="ui-icon-person"
							(click)="login()"></button>
				</div>

				<div class="col-12" *ngIf="loginFormActive">
					<button pButton type="button" label="Forgot Password" style="width: 100%" class="green-btn"
							icon="ui-icon-help" (click)="forgotPassword()"></button>
				</div>

				<div class="col-12" *ngIf="!loginFormActive">
					<button pButton type="button" label="Reset Password" style="width: 100%"
							icon="ui-icon-send" (click)="resetPassword()"></button>
				</div>

				<div class="col-12" *ngIf="!loginFormActive">
					<button class="secondary-btn" pButton type="button" label="Return to login" style="width: 100%" icon="ui-icon-reply"
							(click)="returnToLogin()"></button>
				</div>

			</div>
		</div>
	</div>
	<div class="login-support">
		<span>National Landline:	087 702 6300 | Support:	Support@vmgsoftware.co.za</span>
	</div>
</ng-template>
<!--VMG Online Services url end-->








