export class RequestOptions {
	public baseUrl: string;
	public endpointUrl?: string;
	public method: string;
	public timeout: number;
	public useToken?: boolean;
	public useBranchHeader?: boolean;
	public useCache?: boolean;
	public cacheKeyName?: string;
	public showLoader?: boolean;
	public loaderDelay?: number;
	public loaderText?: string;
	public useCaching?: boolean;

	constructor(init?: Partial<RequestOptions>) {
		this.baseUrl = '';
		this.method = 'get';
		this.timeout = 60000;
		this.useToken = true;
		this.useBranchHeader = true;
		this.useCache = false;
		this.endpointUrl = '';
		this.cacheKeyName = '';
		this.showLoader = true;
		this.loaderDelay = 0;
		this.loaderText = '';
		this.useCaching = false;

		Object.assign(this, init);
	}
}
