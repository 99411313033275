import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ObjectHelpers} from '../../../shared/helpers/object-helpers';
import {LoginRequestModel} from '../../login/models/login-request.model';

@Component({
	selector: 'app-log-on',
	templateUrl: './log-on.component.html',
	styleUrls: ['./log-on.component.scss']
})
export class LogOnComponent implements OnInit {
	public loginForm: FormGroup;
	public loginDetails: LoginRequestModel = new LoginRequestModel();
	public passwordType: string = 'password';
	public showPassword: boolean = false;
	public capsLock: boolean;

	@Output('isFormValid')
	public readonly formValid: EventEmitter<boolean> = new EventEmitter<boolean>();

	@Output()
	public readonly valueChange: EventEmitter<LoginRequestModel> = new EventEmitter<LoginRequestModel>();

	@Output('loginWithEnter')
	public loginFunctionRef: EventEmitter<any> = new EventEmitter();

	@Input()
	public set value(loginRequest: LoginRequestModel) {
		if (!ObjectHelpers.isNullUndefinedOrEmpty(loginRequest)) {
			this.loginDetails = loginRequest;
		}
	}

	constructor(private _formBuilder: FormBuilder) {
	}

	public ngOnInit(): void {
		this.initializeForm();
	}

	public loginWithKeypress(): void {
		this.loginFunctionRef.emit();
	}

	private initializeForm(): void {
		this.loginForm = this._formBuilder.group({
			emailAddress: ['', Validators.compose([Validators.email, Validators.required])],
			password: ['', Validators.compose([Validators.required])]
		});
	}

	public isFormValid(): boolean {
		return this.loginForm.valid;
	}

	public async valueChanged(field: string): Promise<void> {
		const loginDetails = this.loginForm.value as LoginRequestModel;

		if (!ObjectHelpers.isNullOrUndefined(loginDetails)) {
			this.populateFromForm(field);
			this.valueChange.emit(this.loginDetails);
		}

		this.formValid.emit(this.isFormValid());
	}

	private populateFromForm(field: string): void {
		if (field === 'emailAddress') {
			this.loginDetails.emailAddress = this.loginForm.controls.emailAddress.value;
		}
		if (field === 'password') {
			this.loginDetails.password = this.loginForm.controls.password.value;
		}

	}

	public togglePassword(): void {
		if (this.showPassword) {
			this.passwordType = 'password';
		} else {
			this.passwordType = 'text';
		}
		this.showPassword = !this.showPassword;
	}

	public checkCapsLock(event) {
		if (ObjectHelpers.functionExists(event, 'getModifierState')) {
			this.capsLock = event.getModifierState('CapsLock');
		}
	}

}
