
<div class="topbar clearfix">
	<div class="topbar-left">
		<div class="logo"></div>
	</div>
	<div class="grid topbar-right bar">
		<div class="col-fixed" id="menu" style="width:0; padding-left:0; padding-right:0;">
			<a id="menu-button" href="#" (click)="app.onMenuButtonClick($event)" *ngIf="!app.disableMenu">
				<i></i>
			</a>
		</div>
		<div class="col heading">
			<label class="heading-label">{{pageHeading}}</label>&nbsp;
		</div>
		<div class="col-fixed utility-items">
<!--			<a style="color: #eaeaea !important; margin-right: 10px; margin-top: 3px;">-->
<!--				<p-multiSelect [options]="branches" group="true" [(ngModel)]="this.selectedBranch" [filter]="false" (onPanelShow)="branchSelectionLimit()"-->
<!--							   [showHeader]="false" (onChange)="branchSelected($event)" [selectionLimit]="this.selectionLimit"-->
<!--							   [dropdownIcon]="'pi pi-sitemap'" scrollHeight="250px" pTooltip="Select Branch">-->
<!--					<ng-template let-group pTemplate="group">-->
<!--						<div class="flex align-items-center">-->
<!--							<span>{{ group.label }}</span>-->
<!--						</div>-->
<!--					</ng-template>-->
<!--				</p-multiSelect>-->
<!--			</a>-->
			<a *ngIf="app.emptyCodesList.length > 0" style="color: #FFC107 !important; margin-left:8px; cursor:pointer" class="relative"
			(click)="app.showMessage = true">
				<i class="topbar-icon material-icons warning-pulse" pTooltip="Notifications">warning</i>
			</a>
			<a style="color: #eaeaea !important; margin-left:8px; cursor:pointer" class="relative" >
				<i class="topbar-icon material-icons" pTooltip="Notifications">notifications</i>
				<span class="absolute" style="top: -1px; right: -1px;">
					<span style="border-radius: 3px;
					  font-size: 9px;
					  padding:2px;
					  background-color: red;">2</span>
				</span>
			</a>
			<a style="color: #eaeaea !important; margin-left:8px; cursor:pointer" (click)="app.switchCompany();">
				<i class="topbar-icon material-icons" pTooltip="Switch Company">swap_horiz</i>
			</a>
			<a style="color: #eaeaea !important; margin-left:8px; cursor:pointer" (click)="app.logout()">
				<i class="topbar-icon material-icons" pTooltip="Logout">power_settings_new</i>
			</a>
			<ul class="topbar-items animated fadeInDown" [ngClass]="{'topbar-items-visible': app.topbarMenuActive}">
				<li #settings [ngClass]="{'active-top-menu':app.activeTopbarItem === settings}">
					<a href="#" (click)="app.onTopbarItemClick($event,settings)">
						<i class="topbar-icon material-icons">more_vert</i>
						<span class="topbar-item-name">Menu</span>
					</a>
					<ul class="ultima-menu animated fadeInDown">
						<li role="menuitem" style="text-align: left;">
							<a (click)="app.refreshPage()"
							   style="cursor: pointer">
								<i class="material-icons">refresh</i>
								<span>Reload Page</span>
							</a>
						</li>
						<li role="menuitem" style="text-align: left;">
							<a (click)="app.maximizeWindow()"
							   style="cursor: pointer">
								<i class="material-icons">aspect_ratio</i>
								<span>Fullscreen Mode</span>
							</a>
						</li>
					</ul>
				</li>
			</ul>
		</div>
	</div>
</div>
