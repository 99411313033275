import { Injectable } from '@angular/core';
import {GenericEntityService} from '../shared/generic-entity.service';
import {ApiService} from '../shared/api.service';
import {CacherService} from '../../pages/shared/services/cacher/cacher.service';
import {NotificationsService} from '../../pages/shared/services/notifications/notifications.service';
import {Globals} from '../../pages/shared/globals';
import {IClient} from '../../models/global/client';
import {IVehicleModel} from '../../models/dealership/ivehicle-model';
import {RequestOptions} from '../../models/shared/request-options.model';
import {lastValueFrom, Observable} from 'rxjs';
import {ResponseWrapper} from '../../models/IResponseWrapper';
import {IMmCodeDetail} from '../../models/dealership/mmcode-detail';
import {IClientInfoResponse, IClientSearch} from "../../models/global/payloads/client-search";
import {ColumnsModel} from "../../models/dealership/columns/columns.model";
import {HttpClient} from "@angular/common/http";
import {IMainStockDetails} from "../../models/dealership/views/main-stock-details";

@Injectable({
	providedIn: 'root'
})
export class ClientService extends GenericEntityService<IClient, number> {

	public isEditMode: boolean = false;

	constructor(
		protected apiService: ApiService,
		private cacherService: CacherService,
		protected notificationsService: NotificationsService,
		private httpClient: HttpClient
	) {
		const baseUrl: string = Globals.GlobalApiBaseUrl + '/Clients';
		super(notificationsService, apiService, baseUrl
		);
	}

	public searchClients(clientKeywords: IClientSearch, requestOptions: RequestOptions): Observable<ResponseWrapper<Array<IClientInfoResponse>>> {
		requestOptions.method = 'post';
		const endpoint: string = requestOptions.endpointUrl;
		requestOptions.baseUrl = Globals.GlobalApiBaseUrl + '/Clients/SearchClients';
		return this.apiService.apiRequest<ResponseWrapper<Array<IClientInfoResponse>>>(endpoint, clientKeywords, requestOptions);
	}

	public async getClientTableColumns(): Promise<Array<ColumnsModel>> {
		const clientTables$ = this.httpClient.get<Array<ColumnsModel>>('assets/demo/data/client-list-view.json');
		return await lastValueFrom(clientTables$);
	}

	public populateClientTable(client: IClientInfoResponse): IClientInfoResponse {
		return {
			client: client.client,
			address: client.address
		};
	}

}
