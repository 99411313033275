import {Component, Input, OnInit} from '@angular/core';
import {AppMainComponent} from '../../app.main.component';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {CacherService} from '../../pages/shared/services/cacher/cacher.service';
import {MenuItem} from 'primeng/api';
import {PermissionService} from "../../pages/shared/services/permissions/permission.service";

@Component({
	selector: 'app-menu',
	templateUrl: './menu.component.html',
	styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
	public model: any[] = [];
	public enabledFeatures: Array<string> = [];

	constructor(public app: AppMainComponent,
				private _permisssionService: PermissionService,
				private _cacherService: CacherService) {
	}

	async ngOnInit() {
		await this.enableBranchFeatures();
	}

	private async removeFilteredItemInCRMTableComponent(): Promise<void> {
		if (this._cacherService.hasKey('selectedLeadValueInput')) {
			this._cacherService.removeByKey('selectedLeadValueInput');
		}
	}

	private async enableBranchFeatures() {

		const stockMenuLabel: string = (this.enabledFeatures.includes('DmsLite')) ? 'Stock Management' : 'Stock Management';

		this.model.push(
			{
				label: stockMenuLabel, icon: 'drive_eta',
				visible: await this._permisssionService.validatePermission(['Admin', 'Stock Management']),
				routerLink: ['/'],
			},
			{
				label: 'Clients', icon: 'group',
				visible: await this._permisssionService.validatePermission(['Admin', 'Clients Menu']),
				routerLink: ['/clients']
			},
			{
				label: 'Suppliers', icon: 'shopping_cart',
				visible: await this._permisssionService.validatePermission(['Admin', 'Suppliers Menu']),
				routerLink: ['/suppliers']
			},
			{
				label: 'Company Settings', icon: 'settings',
				visible: await this._permisssionService.validatePermission(['Admin', 'Company Settings']),
				routerLink: ['/company/settings'],
			},
			{
				label: 'Reports', icon: 'assignment',
				visible: await this._permisssionService.validatePermission(['Admin', 'Reports']),
				routerLink: ['/reports']
			});
	}
}

@Component({
	// tslint:disable-next-line:component-selector
	selector: '[app-submenu]',
	template: `
		<ng-template ngFor let-child let-i="index" [ngForOf]="(root ? item : item.items)">
			<li [ngClass]="{'active-menuitem': isActive(i)}" [class]="child.badgeStyleClass"
				*ngIf="child.visible === false ? false : true">
				<a [href]="child.url||'#'" (click)="itemClick($event,child,i)" (mouseenter)="onMouseEnter(i)"
				   class="ripplelink" *ngIf="!child.routerLink"
				   [attr.tabindex]="!visible ? '-1' : null" [attr.target]="child.target">
					<i *ngIf="child.icon" class="material-icons">{{ child.icon }}</i>
					<span>{{ child.label }}</span>
					<span class="menuitem-badge" *ngIf="child.badge">{{ child.badge }}</span>
					<i class="material-icons submenu-icon" *ngIf="child.items">keyboard_arrow_down</i>
				</a>

				<a (click)="itemClick($event,child,i)" (mouseenter)="onMouseEnter(i)" class="ripplelink"
				   *ngIf="child.routerLink"
				   [routerLink]="child.routerLink" routerLinkActive="active-menuitem-routerlink"
				   [routerLinkActiveOptions]="{exact: true}" [attr.tabindex]="!visible ? '-1' : null"
				   [attr.target]="child.target">
					<i *ngIf="child.icon" class="material-icons">{{ child.icon }}</i>
					<span>{{ child.label }}</span>
					<span class="menuitem-badge" *ngIf="child.badge">{{ child.badge }}</span>
					<i class="material-icons submenu-icon" *ngIf="child.items">keyboard_arrow_down</i>
				</a>
				<div class="layout-menu-tooltip">
					<div class="layout-menu-tooltip-arrow"></div>
					<div class="layout-menu-tooltip-text">{{ child.label }}</div>
				</div>
				<ul app-submenu [item]="child" *ngIf="child.items" [visible]="isActive(i)" [parentActive]="isActive(i)"
					[@children]="root ? isActive(i) ?
                    'visible' : 'hidden' : isActive(i) ? 'visibleAnimated' : 'hiddenAnimated'"></ul>
			</li>
		</ng-template>
	`,
	animations: [
		trigger('children', [
			state('hiddenAnimated', style({
				height: '0px'
			})),
			state('visibleAnimated', style({
				height: '*'
			})),
			state('visible', style({
				height: '*',
				'z-index': 20
			})),
			state('hidden', style({
				height: '0px',
				'z-index': '*'
			})),
			transition('visibleAnimated => hiddenAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
			transition('hiddenAnimated => visibleAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
		])
	]
})
export class SubMenuComponent {
	@Input() public item: MenuItem;
	@Input() public root: boolean;
	@Input() public visible: boolean;

	// tslint:disable-next-line:variable-name
	public _parentActive: boolean;
	public activeIndex: number;

	constructor(public app: AppMainComponent) {
	}

	itemClick(event: Event, item: MenuItem, index: number) {
		if (this.root) {
			this.app.menuHoverActive = !this.app.menuHoverActive;
		}

		// avoid processing disabled items
		if (item.disabled) {
			event.preventDefault();
			return true;
		}

		// activate current item and deactivate active sibling if any
		this.activeIndex = (this.activeIndex === index && this.root) ? null : index;

		// execute command
		if (item.command) {
			item.command({originalEvent: event, item});
		}

		// prevent hash change
		if (item.items || (!item.url && !item.routerLink)) {
			setTimeout(() => {
				this.app.layoutMenuScrollerViewChild.moveBar();
			}, 450);
			event.preventDefault();
		}

	}

	onMouseEnter(index: number) {
		if (this.root && this.app.menuHoverActive
			&& !this.app.checkDeviceSize('isMobile') && !this.app.checkDeviceSize('isTablet')) {
			this.activeIndex = index;
		}
	}

	isActive(index: number): boolean {
		return this.activeIndex === index;
	}

	@Input() get parentActive(): boolean {
		return this._parentActive;
	}

	set parentActive(val: boolean) {
		this._parentActive = val;

		if (!this._parentActive) {
			this.activeIndex = null;
		}
	}
}
