<div class="modal-inner-content-style" style="padding-top: 2px;">
	<div class="grid  justify-content-between">
		<div class="col-12" style="text-align: left;
		   margin-right: 10px;
		    margin-left: 10px;
		     display: flex; align-items: center;">
			<p-chips [(ngModel)]="clientSearchItems" #chipsInput separator="" placeholder="Client Search" [ngStyle]="{'width': '100%'}" addOnBlur="true"></p-chips>
		</div>
	</div>
	<div class="col-12" >
		<p-table #dt
				 [value]="clients"
				 selectionMode="single"
				 [totalRecords]="clients.length"
				 [(selection)]="selectedClient"
				 [loading]="loading"
				 [responsive]="true"
				 (onRowSelect)="onRowSelect($event)"
				 dataKey="id"
				 *ngIf="this?.clients?.length > 0 else noResultsTable">
			<ng-template pTemplate="header">
				<tr>
					<th>Code</th>
					<th>Client</th>
					<th>Contact Number</th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-rowData>
				<tr [pSelectableRow]="rowData" (dblclick)="populate(this.selectedClient)" class="client-table text-left">
					<td>{{rowData.code}}</td>
					<td>{{rowData.firstName}} {{rowData.lastName}}</td>
					<td>{{rowData.contactNumber}}</td>
				</tr>
			</ng-template>
		</p-table>
	</div>
	<ng-template #noResultsTable>
		<p-table>
			<ng-template pTemplate="header">
				<tr>
					<th style="text-align:center;">Results empty</th>
				</tr>
			</ng-template>
		</p-table>
	</ng-template>
</div>

<p-footer>
	<div class="grid justify-content-between align-content-end" style="padding-top: 20px">
		<div class="col-6" style="text-align-last: start;">
			<button style="margin-right: 10px;"
					class="flat"
					pButton
					type="button"
					label="CLOSE"
					(click)="close()">
			</button>
		</div>
		<div class="col-6" style="text-align-last: end;">
			<button *ngIf="selectedClient"
					style="margin-right: 10px"
					class="flat"
					pButton
					type="button"
					label="POPULATE"
					(click)="populate(selectedClient)">
			</button>
			<button
				class="flat"
				pButton
				type="button"
				label="SEARCH"
				(click)="searchClient()">
			</button>
		</div>
	</div>
</p-footer>

