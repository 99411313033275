<div class="flex flex-wrap gap-4">
	<button class="col-12 justify-content-center"
			(click)="setReportName('Advert Previewer','Stock Page -  STOCK PAGE ADVERT')"
			pButton>Print Advert</button>
	<button class="col-12 justify-content-center"
			(click)="setReportName('Price Board With Image Previewer','Stock Page - Price Board With Image')"
			pButton>Print Price Board</button>
	<button class="col-12 justify-content-center"
			(click)="setReportName('Price Board w/o Image Previewer','Stock Page -  Price Board Without Image')"
			pButton>Print Price Board (w/o Photo)</button>
</div>
