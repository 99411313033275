import {Injectable} from '@angular/core';
import {CompanyBranchFeature} from '../../models/company-branch-feature.model';
import {Globals} from '../../../shared/globals';
import {CacheType} from '../../../shared/services/cacher/caching-options';
import {CacherService} from '../../../shared/services/cacher/cacher.service';
import {Router} from '@angular/router';
import {UserInformation} from '../../login/models/login-response.model';
import {PermissionService} from '../../../shared/services/permissions/permission.service';
import {Logger} from "../../../shared/services/logger/logger";
import {ObjectHelpers} from "../../../shared/helpers/object-helpers";
import {StringHelpers} from "../../../shared/helpers/string-helpers";

export const BRANCH_IDS = 'BranchIds';
export const COMPANY_ID = 'CompanyId';


@Injectable({
	providedIn: 'root'
})
export class CompanyService {
	public userInformation: UserInformation;
	public allBranchIds: number[];

	constructor(
		private cacherService: CacherService,
		private permissionService: PermissionService,
		private router: Router
	) {}

	public async companySelected(companyBranchFeature: CompanyBranchFeature): Promise<void> {

		const companyBranchFeatures = this.userInformation.user.companyBranchFeatures.filter(
			x => x.companyId === companyBranchFeature.companyId);

		await this.branchSelection(companyBranchFeatures, companyBranchFeature.companyId);
	}

	public async branchSelection(selectedCompany: CompanyBranchFeature[], companyId: number): Promise<void> {

		if (!ObjectHelpers.isNullUndefinedOrEmpty(selectedCompany) && !StringHelpers.isNumberNullOrUndefined(companyId)) {

			const uniqueBranches = this.getUniqueBranches(selectedCompany);
			this.userInformation.user.companyBranchFeatures = uniqueBranches;
			this.allBranchIds = uniqueBranches.map(feature => feature.branchId);

			await this.setCachingCompanySelect(companyId, this.allBranchIds);
		}
	}

	private getUniqueBranches(features: CompanyBranchFeature[]): CompanyBranchFeature[] {

		if (!ObjectHelpers.isNullUndefinedOrEmpty(features)) {
			const uniqueBranchesMap = new Map<number, CompanyBranchFeature>();

			features.forEach(feature => {
				uniqueBranchesMap.set(feature.branchId, feature);
			});
			return Array.from(uniqueBranchesMap.values());
		}
	}

	public async setCachingCompanySelect(companyId: number, branchIds: number[]): Promise<void> {

		try {
			Globals.BranchIds = branchIds;
			Globals.CompanyId = companyId;
			Globals.RegionId = this.userInformation.companyAddress.regionId;

			const permissions = this.userInformation.employeeUserInformation.find(x => x.companyId === companyId);
			this.permissionService.addRoleWithPermissions(permissions);
			this.userInformation.employeeUserInformation = [permissions];

			this.cacherService.setData(BRANCH_IDS, branchIds, { cacheType: CacheType.Local });
			this.cacherService.setData(COMPANY_ID, companyId, { cacheType: CacheType.Local });
			this.cacherService.setData('UserInformation', this.userInformation, { cacheType: CacheType.Local });

			await this.router.navigate(['/']);
		} catch (e) {
			Logger.logError('Failed to set caching company', e);
		}
	}
}
