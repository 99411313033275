import {Component, OnInit} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {ObjectHelpers} from '../../helpers/object-helpers';

@Component({
	selector: 'app-loader',
	templateUrl: './loader.component.html',
	styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

	public content: string;

	constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) {
	}

	ngOnInit() {
		if (!ObjectHelpers.isNullUndefinedOrEmpty(this.config.data)){
			this.content = this.config.data.content;
		}

	}

}
