import {Injectable} from '@angular/core';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {LoaderComponent} from './loader.component';
import {ObjectHelpers} from '../../helpers/object-helpers';

@Injectable({
	providedIn: 'root',
})
export class LoaderService {

	public ref: DynamicDialogRef;

	constructor(public dialogService: DialogService) {
	}

	public showLoader(text: string): void {
		if (ObjectHelpers.isNullOrUndefined(this.ref)) {
			this.ref = this.dialogService.open(LoaderComponent, {
				data: {
					content:  '',
					id: 'test',
					class: 'test'
				},
				style: {
					'border-radius': '50%',
					'max-width': '80px',
					'max-height': '80px',
					'border': '2px solid #8BC34A',
					'background': 'white',
				},
				contentStyle: {
					'background': 'transparent',
				},
				styleClass: 'Test',
				closable: false,
				showHeader: false
			});
		}

	}

	public hideLoader(): void {
		if (!ObjectHelpers.isNullOrUndefined(this.ref)) {
			this.ref.close();
			this.ref = undefined;
		}
	}
}
